<template>
  <b-modal
    id="modal-center"
    v-model="isShow"
    centered
    title="Problem with the deal"
  >
    <b-row>
      <b-col md="12">
        <b-form-group
          label-for="deal_problem_text"
          label="Problem"
        >
          <b-input-group class="input-group-merge">
            <b-form-textarea
              id="deal_problem_text"
              v-model="message"
              name="message"
              placeholder="Problem"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <template #modal-footer="{ cancel }">
      <b-button
        variant="secondary"
        @click="cancel"
      >
        {{ $t('cancel') }}
      </b-button>
      <b-button
        variant="primary"
        class="btn-cart"
        :disabled="!message"
        @click="create"
      >
        <feather-icon
          icon="EditIcon"
          class="mr-50"
        />
        <span>Create problem</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>

import {
  BButton, BCol, BFormGroup, BFormTextarea, BInputGroup, BRow,
} from 'bootstrap-vue';

export default {
  name: 'TradingDealProblemModal',
  components: {
    BRow, BCol, BFormGroup, BFormTextarea, BButton, BInputGroup,
  },
  props: {
    isShowModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      message: '',
    };
  },
  computed: {
    isShow: {
      get() {
        return this.isShowModal;
      },
      set(val) {
        this.$emit('update:isShowModal', val);
      },
    },
  },
  methods: {
    create() {
      this.$store.dispatch('deal/createProblem', {
        id: this.$route.params.id,
        message: this.message,
      });

      this.message = '';
      this.isShow = false;
    },
  },
};
</script>
