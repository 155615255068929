<template>
  <div>
    <div
      class="d-flex align-items-center mw-80 my-2 doc-name"
    >
      <div class="mr-1">
        <feather-icon
          icon="FileTextIcon"
          size="36"
        />
      </div>
      <div class="mw-100">
        <div class="d-flex flex-column">
          <div class="document-preview-type">
            {{ $t('documentNotUploadedYet', { documentName, documentType, role }) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    documentName: {
      type: String,
      default: '',
    },
    documentType: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped lang="scss">
.document-preview {
  cursor: pointer;
  overflow: hidden;

  &-type {
    font-weight: bold;
    text-transform: none;
  }
  &-type::first-letter {
    text-transform: uppercase;
  }
}

.doc-name {
  width: 80% !important;
  opacity: 0.4;
  margin-top: 12px !important;
}
</style>
