<template>
  <b-card>
    <b-row>
      <b-col
        cols="10"
      >
        <h5 class="mb-0 text-capitalize">
          {{ isAdmin ? $t(role) : $t('manager') }}
        </h5>
      </b-col>
      <b-col
        cols="2"
        class="d-flex justify-content-center align-items-center p-0"
      >
        <b-badge
          :pill="true"
          variant="warning"
        >
          {{ unreadCount }}
        </b-badge>
      </b-col>
    </b-row>
    <div class="message-placeholder my-1">
      <chat-log
        v-if="loadMsg"
        :ref="role"
        class="user-chats scroll-area ps"
        :chat-messages="chatData.messages"
        :role="role"
        @load-next-page="loadNextPage"
        @read-message="readMessage"
      />
    </div>
    <b-form @submit.prevent="sentMsg">
      <b-form-textarea
        v-model="formMsg"
        :placeholder="$t('typeMessageHere')"
        @focus="onFocusReadMessage"
        @keydown.enter="submit"
      />
      <b-button
        :disabled="isHaveMinLength"
        class="mt-2"
        variant="primary"
        type="submit"
      >
        {{ $t('sendMessage') }}
      </b-button>
    </b-form>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BForm, BFormTextarea, BButton,
} from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import chatLog from '@core/components/chat/ChatLog';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BBadge,
    BFormTextarea,
    BButton,
    BForm,
    chatLog,
    VuePerfectScrollbar,
  },
  props: {
    role: {
      type: String,
      default: '',
    },
    chatData: {
      type: Object,
      default: (() => {}),
    },
  },
  data() {
    return {
      formMsg: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
      loadMsg: false,
      unreadCount: null,
      minLength: 3,
    };
  },
  computed: {
    ...mapGetters({
      user: 'profile/profile',
      chatBuyer: 'deal/chatBuyer',
      chatSupplier: 'deal/chatSupplier',
    }),
    isHaveMinLength() {
      return this.formMsg.length < this.minLength;
    },
    chatParams() {
      return this.role === 'buyer' ? this.chatBuyer : this.chatSupplier;
    },
    currentUser() {
      return this.user;
    },
    userId() {
      return this.currentUser.id || null;
    },
    isAdmin() {
      return this.$store.getters['profile/isAdmin'];
    },
  },
  methods: {
    ...mapActions({
      fetchMessages: 'deal/fetchMessages',
    }),
    submit(e) {
      if (!e.shiftKey) {
        this.sentMsg();
      }
    },
    onFocusReadMessage() {
      const msg = this.chatData.messages.reduce((acc, a) => {
        if (a.user.id !== this.userId && !a.read_at) {
          acc.push(a.id);
        }
        return acc;
      }, []);
      if (!msg.length) return;
      this.readMessage(msg);
    },
    loadNextPage() {
      if (this.chatParams.pagination.last_page <= this.chatParams.pagination.current_page) return;
      const page = this.chatParams.pagination.current_page += 1;
      this.getChat(page);
    },
    scrollToBottomInChatLog() {
      const refChatLogPS = this.$refs[this.role];
      const scrollEl = refChatLogPS.$el;
      scrollEl.scrollTop = scrollEl.scrollHeight;
    },
    async sentMsg() {
      this.onFocusReadMessage();
      const params = {
        dealId: this.$route.params.id,
        type: this.role.toLowerCase(),
        message: this.formMsg,
      };
      await this.$store.dispatch('deal/sentChatMessage', params);
      this.scrollToBottomInChatLog();
      this.formMsg = '';
    },
    ...mapActions({
      fetchMessages: 'deal/fetchMessages',
      readMessageChat: 'deal/readMessagesChat',
      countUnreadMessage: 'deal/getCountUnreadChat',
    }),
    getCountUnreadChat() {
      this.countUnreadMessage({ dealId: this.$route.params.id, type: this.role })
        .then(res => {
          this.unreadCount = res;
        });
    },
    readMessage(ids) {
      this.readMessageChat({ dealId: this.$route.params.id, type: this.role, ids })
        .finally(res => {
          this.unreadCount = res;
        });
    },
    getChat(page) {
      this.fetchMessages({ dealId: this.$route.params.id, type: this.role, page });
    },
    getChatMsg(page) {
      const promises = [this.fetchMessages({ dealId: this.$route.params.id, type: this.role, page })];
      Promise.all(promises)
        .then(() => {
          this.loadMsg = true;
        })
        .finally(() => {
          this.scrollToBottomInChatLog();
        });
    },
  },
  mounted() {
    this.getChatMsg(1, 1);
    this.getCountUnreadChat();
  },
};
</script>

<style lang="scss" scoped>
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
@import "src/@core/scss/base/pages/app-invoice.scss";
.message-placeholder {
  border-radius: 6px;
  height: 300px;
  background: url("../../../assets/images/placeholders/message-placeholder.svg") no-repeat center center $product-details-bg;
  background-size: cover;
}
</style>

<style lang="scss">
@import '../../../@core/scss/base/core/colors/palette-variables';
@import 'src/@core/scss/base/components/variables-dark';
.ps {
  height: 300px;
  overflow-y: auto!important;
}
.dark-layout {
  .message-placeholder {
    background-color: $theme-dark-body-bg;
  }
}
</style>
