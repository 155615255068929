<template>
  <b-card no-body>
    <b-card-header>
      <span class="company-card-header">
        {{ title }}
      </span>
    </b-card-header>
    <b-card-body class="d-flex">
      <b-avatar
        class="mr-2"
        size="40"
        variant="light-primary"
        :src="avatarImage"
        :text="avatarText"
      />
      <div>
        <h6>{{ companyName }}</h6>
        <span class="company-card-country">
          {{ country }}
        </span>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BAvatar,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BAvatar,
  },
  props: {
    title: {},
    user: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    avatarText() {
      return avatarText(this.companyName);
    },
    avatarImage() {
      return this.user?.profile?.avatar_url || '';
    },
    companyName() {
      return this.user?.company_name || this.user?.company.company_name || '';
    },
    country() {
      return this.user?.profile?.country?.name || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
.company-card-header {
  text-transform: uppercase;
}
.company-card-country {
  font-size: 12px;
}
</style>
