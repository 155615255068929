<template>
  <div>
    <b-card
      no-body
      :class="{'open': isDeliveryVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isDeliveryVisible}"
        :aria-expanded="isDeliveryVisible ? 'true' : 'false'"
        aria-controls="delivery-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateDeliveryVisible(!isDeliveryVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('delivery') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="delivery-collapse"
        v-model="isDeliveryVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <h5
            v-if="!selectedDelivery"
            class="mb-1"
          >
            {{ $t('selectDeliveryOption') }}
          </h5>
          <div>
            <b-row>
              <b-col
                v-for="delivery in options.delivery"
                :key="delivery.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :disabled="![1,2,3,4].includes(dealCurrentStatus) || deliveryInvoiceForPayment && deliveryInvoiceForPayment.receipt_sent"
                  :variant="delivery"
                  :selected-variant="selectedDelivery"
                  :class="{
                    'disable-card': deliveryInvoiceForPayment && deliveryInvoiceForPayment.receipt_sent && delivery.id === 2
                      || dealCurrentStatus > 2 && selectedDelivery !== delivery.id,
                    'no-selectable': dealCurrentStatus > 2
                  }"
                  @select="selectDelivery"
                />
              </b-col>
            </b-row>
            <template v-if="selectedDelivery === 1">
              <b-button
                v-if="isAllowToRequestDeliveryCost && dealCurrentStatus < 3"
                class="mt-2"
                variant="primary"
                @click="postDelivery"
              >
                {{ $t('requestDeliveryInvoice') }}
              </b-button>
              <div>
                <doc-section-header
                  v-if="deliveryInvoice"
                  :header-text="String($t('payments'))"
                  :sub-header-text="!deliveryInvoiceIsReceipt ?
                    String($t('payInvoiceAndUploadReceipt', { documentName: $t('deliveryInvoice')}))
                    : String($t('paymentReceiptToDocument', { documentName: $t('deliveryInvoice')}))
                  "
                  divider="after"
                  class="mt-2"
                  header-text-size="medium"
                >
                  <template #content>
                    <transactions-documents-list
                      :documents="[deliveryInvoice]"
                      :is-receipt="deliveryInvoiceIsReceipt"
                      :is-removable="deliveryInvoiceIsReceipt && dealCurrentStatus < 3 && !deliveryInvoiceForPayment.receipt_sent"
                      @fetch-deal="fetchDeal"
                    />
                    <drop-zone
                      v-if="!deliveryInvoiceIsReceipt"
                      @add-file="addDeliveryInvoice"
                    />
                    <template v-if="deliveryInvoiceForPayment && !deliveryInvoiceForPayment.receipt_sent">
                      <b-button
                        class="mt-1 section-margin"
                        variant="primary"
                        :disabled="!deliveryInvoiceIsReceipt"
                        @click="sendDelivery"
                      >
                        {{ $t('paymentSent') }}
                      </b-button>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-items-center section-margin"
                        :class="{ 'margin-top-12' : !deliveryInvoiceIsReceipt }"
                      >
                        <b-avatar
                          size="32"
                          variant="light-success"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                          />
                        </b-avatar>
                        <h5 class="mb-0 ml-1">
                          {{ $t('deliveryPaymentSent') }}
                        </h5>
                      </div>
                    </template>
                  </template>
                </doc-section-header>
              </div>
            </template>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card
      no-body
      :class="{'open': isClearanceVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isClearanceVisible}"
        :aria-expanded="isClearanceVisible ? 'true' : 'false'"
        aria-controls="clearance-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateClearanceVisible(!isClearanceVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('customs') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="clearance-collapse"
        v-model="isClearanceVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <h5
            v-if="!selectedCustom"
            class="mb-1"
          >
            {{ $t('selectCustomOption') }}
          </h5>
          <div>
            <b-row>
              <b-col
                v-for="custom in options.customs"
                :key="custom.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :disabled="![1, 2, 3, 4, 5].includes(dealCurrentStatus) || clearanceInvoiceForPayment && clearanceInvoiceForPayment.receipt_sent"
                  :variant="custom"
                  :selected-variant="selectedCustom"
                  :class="{
                    'disable-card': clearanceInvoiceForPayment && clearanceInvoiceForPayment.receipt_sent && custom.id === 2
                      || dealCurrentStatus > 2 && selectedCustom !== custom.id,
                    'no-selectable': dealCurrentStatus > 2
                  }"
                  @select="selectCustom"
                />
              </b-col>
            </b-row>
            <template v-if="selectedCustom === 1">
              <b-button
                v-if="isAllowToRequestClearanceCost && dealCurrentStatus < 3"
                class="mt-2"
                variant="primary"
                @click="postCustoms"
              >
                {{ $t('requestClearanceCost') }}
              </b-button>
              <div>
                <doc-section-header
                  v-if="clearanceInvoice"
                  :header-text="String($t('payments'))"
                  :sub-header-text="!clearanceInvoiceIsReceipt ?
                    String($t('payInvoiceAndUploadReceipt', { documentName: $t('clearanceInvoice')}))
                    : String($t('paymentReceiptToDocument', { documentName: $t('clearanceInvoice')}))
                  "
                  divider="after"
                  class="mt-2"
                  header-text-size="medium"
                >
                  <template #content>
                    <transactions-documents-list
                      :documents="[clearanceInvoice]"
                      :is-receipt="clearanceInvoiceIsReceipt"
                      :is-removable="clearanceInvoiceIsReceipt && dealCurrentStatus < 3 && !clearanceInvoiceForPayment.receipt_sent"
                      @fetch-deal="fetchDeal"
                    />
                    <drop-zone
                      v-if="!clearanceInvoiceIsReceipt"
                      @add-file="addClearanceInvoice"
                    />
                    <template v-if="clearanceInvoiceForPayment && !clearanceInvoiceForPayment.receipt_sent">
                      <b-button
                        class="mt-1 section-margin"
                        variant="primary"
                        :disabled="!clearanceInvoiceIsReceipt"
                        @click="sendClearance"
                      >
                        {{ $t('customsPaymentSent') }}
                      </b-button>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-items-center section-margin"
                        :class="{ 'margin-top-12' : !clearanceInvoiceIsReceipt }"
                      >
                        <b-avatar
                          size="32"
                          variant="light-success"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                          />
                        </b-avatar>
                        <h5 class="mb-0 ml-1">
                          {{ $t('customsPaymentSent') }}
                        </h5>
                      </div>
                    </template>
                  </template>
                </doc-section-header>
              </div>
            </template>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BRow, BCol, BButton, BAvatar, BCardHeader, BCollapse, BCard,
} from 'bootstrap-vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import DeliveryCardVariant from '@/views/trading/trading-deals/delivery/DeliveryCardVariant.vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import DocSectionHeader from '@/views/trading/trading-deals/components/DocSectionHeader.vue';

export default {
  components: {
    DocSectionHeader,
    BCardBody,
    BRow,
    BCol,
    DeliveryCardVariant,
    DropZone,
    TransactionsDocumentsList,
    BButton,
    BAvatar,
    BCardHeader,
    BCollapse,
    BCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isDeliveryInvoiceReady: false,
      isCustomInvoiceReady: false,
      delivery_cost: null,
      dropzoneOptions: {
        previewsContainer: false,
        autoProcessQueue: false,
        url: 'localhost',
      },
      selectedDelivery: null,
      selectedCustom: null,
      deliveryInvoiceUploaded: null,
      clearanceInvoiceUploaded: null,
      isDeliveryVisible: true,
      isClearanceVisible: true,
    };
  },
  computed: {
    options() {
      return {
        delivery: [
          { icon: 'ShoppingCartIcon', name: this.$t('pickupFromWarehouse'), id: 2 },
          { icon: 'TruckIcon', name: this.$t('needDeliveryServices'), id: 1 },
        ],
        customs: [
          { icon: 'BriefcaseIcon', name: this.$t('companyWillClearanceOwn'), id: 2 },
          { icon: 'GiftIcon', name: this.$t('needClearanceServices'), id: 1 },
        ],
      };
    },
    currentRoute() {
      const { id } = this.$route.params;
      return id || '';
    },
    deal() {
      return this.$store.state.deal.deal;
    },
    legal() {
      return this.deal.legal;
    },
    deliveryDocuments() {
      return this.legal.invoices.filter(item => item.type === 'delivery');
    },
    deliveryInvoice() {
      const deliveryInvoiceReceipt = this.legal.documents.find(
        item => item.type === 'delivery_payment_receipt',
      );

      if (deliveryInvoiceReceipt) {
        return deliveryInvoiceReceipt;
      }

      return this.legal.invoices.find(item => item.type === 'delivery');
    },
    deliveryInvoiceForPayment() {
      return this.legal.invoices.find(item => item.type === 'delivery');
    },
    deliveryInvoiceIsReceipt() {
      return this.deliveryInvoice?.type === 'delivery_payment_receipt';
    },
    clearanceDocuments() {
      return this.legal.invoices.filter(item => item.type === 'clearance');
    },
    clearanceInvoice() {
      const clearanceInvoiceReceipt = this.legal.documents.find(
        item => item.type === 'clearance_payment_receipt',
      );

      if (clearanceInvoiceReceipt) {
        return clearanceInvoiceReceipt;
      }

      return this.legal.invoices.find(item => item.type === 'clearance');
    },
    clearanceInvoiceForPayment() {
      return this.legal.invoices.find(item => item.type === 'clearance');
    },
    clearanceInvoiceIsReceipt() {
      return this.clearanceInvoice?.type === 'clearance_payment_receipt';
    },
    dealCurrentStatus() {
      return this.$store.state.deal.deal.status;
    },
    isAllowToRequestDeliveryCost() {
      return this.selectedDelivery === 1 && !this.legal.invoices.some(item => item.type === 'delivery');
    },
    isAllowToRequestClearanceCost() {
      return this.selectedCustom === 1 && !this.legal.invoices.some(item => item.type === 'clearance');
    },
    isDeliveryReceiptSent() {
      return this.legal.invoices.find(item => item.type === 'delivery')?.receipt_sent || false;
    },
    isClearanceReceiptSent() {
      return this.legal.invoices.find(item => item.type === 'clearance')?.receipt_sent || false;
    },
    isAllowUploadDeliveryReceipts() {
      return [1, 2, 3, 4].includes(this.dealCurrentStatus)
          && this.legal.invoices.some(item => item.type === 'delivery')
          && !this.isDeliveryReceiptSent;
    },
    isAllowUploadClearanceReceipts() {
      return [1, 2, 3, 4, 5].includes(this.dealCurrentStatus)
          && this.legal.invoices.some(item => item.type === 'clearance')
          && !this.isClearanceReceiptSent;
    },
  },
  watch: {
    dealCurrentStatus() {
      this.selectedDelivery = this.$store.state.deal.deal.delivery_type;
    },
    deal: {
      immediate: true,
      handler(val) {
        this.selectedDelivery = val.delivery_type;
        this.selectedCustom = val.clearance_type;
      },
    },
    isDeliveryReceiptSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateDeliveryVisible(false), 500);
        }
      },
    },
    isClearanceReceiptSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateClearanceVisible(false), 500);
        }
      },
    },
  },
  methods: {
    updateDeliveryVisible(val = true) {
      this.isDeliveryVisible = val;
    },
    updateClearanceVisible(val = true) {
      this.isClearanceVisible = val;
    },
    async addDeliveryInvoice(files) {
      const [file] = files;
      this.deliveryInvoiceUploaded = file;

      await this.savePaymentReceipt(files, 'delivery');

      if (this.deliveryInvoiceForPayment?.receipt_sent) {
        await this.sendDelivery();
      }
    },
    async savePaymentReceipt(files, invoiceType) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', `${invoiceType}_payment_receipt`);
      formData.append('legal_id', String(this.$store.state.profile.profile.company.id));
      formData.append('meta', `${invoiceType} payment receipt`);
      formData.append('file', file);

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        await this.fetchDeal();
      });
    },
    async addClearanceInvoice(files) {
      const [file] = files;
      this.clearanceInvoiceUploaded = file;

      await this.savePaymentReceipt(files, 'clearance');

      if (this.clearanceInvoiceForPayment?.receipt_sent) {
        await this.sendClearance();
      }
    },
    async fetchDeal() {
      await this.$store.dispatch('deal/fetch', this.deal.id);
    },
    async sendDelivery() {
      const { data } = await this.$store.dispatch('deal/getDocument', this.deliveryInvoice.id);

      const form = new FormData();
      form.set('receipt', data);
      form.set('stage', 'delivery');
      this.deliveryInvoiceUploaded = null;
      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async sendClearance() {
      const { data } = await this.$store.dispatch('deal/getDocument', this.clearanceInvoice.id);

      const form = new FormData();
      form.set('receipt', data);
      form.set('stage', 'clearance');
      this.clearanceInvoiceUploaded = null;
      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async selectDelivery(deliveryId) {
      this.selectedDelivery = deliveryId;
      await this.postDelivery();
      if (this.selectedDelivery === 2) {
        await this.selectCustom(this.selectedDelivery);
      }
    },
    async postDelivery() {
      await this.postVariant(this.selectedDelivery, 'delivery', 'delivery_type');
    },
    async postCustoms() {
      await this.postVariant(this.selectedCustom, 'clearance', 'clearance_type');
    },
    async selectCustom(customId) {
      this.selectedCustom = customId;
      await this.postCustoms();
    },
    async postVariant(id, type, paramName) {
      try {
        const params = new URLSearchParams();
        params.append(paramName, id);
        await this.$store.dispatch('deal/setDeliveryOptions', { payload: params, id: this.currentRoute, type });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-card {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.no-selectable {
  pointer-events: none;
}

.section-margin {
  margin-bottom: 21px;
}

.margin-top-12 {
  margin-top: 12px !important;
}
</style>
