<template>
  <b-overlay
    class="overlay"
    :show="isLoading"
    rounded="sm"
    no-center
  >
    <template #overlay>
      <div class="loader-container">
        <b-spinner />
      </div>
    </template>

    <section v-if="!isLoading">
      <b-row>
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <trading-deals-products
            :title="$t('products')"
            :user-type="currentUserType"
            :currency="currency"
          />
          <trading-deal-comments
            v-if="currentUserType === 'admin'"
            :title="$t('comments')"
            :is-deal="true"
            :comment-owner="noteOwner"
            :comment-request="noteRequest"
          />
          <trading-deal-transactions
            :title="$t('documentsAndPayments')"
            :user-type="currentUserType"
          />
          <delivery-type-admin
            v-if="currentUserType === 'admin'"
            :currency="currency"
          />
          <delivery-type-buyer
            v-if="currentUserType === 'buyer'"
          />
          <trading-deal-tracking
            :user-type="currentUserType"
            :title="$t('cargoTracking')"
            :buyer="buyer"
            :supplier="supplier"
            :current-delivery-status="currentDeal.delivery_status"
          />
        </b-col>

        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
        >
          <trading-deal-general
            :title="$t('deal')"
            :deal-id="dealId"
            :is-deal="true"
            :info="generalInformation"
            :status="currentStatus"
          />
          <template v-if="currentUserType === 'admin'">
            <trading-new-company
              :title="$t('buyer')"
              :user="buyerInfo"
            />
            <trading-new-company
              :title="$t('supplier')"
              :user="supplierInfo"
            />
          </template>
          <trading-deal-actions
            :title="$t('actionsRequired')"
            :actions="requiredActions"
          />
          <trading-deal-message
            v-if="currentUserType === 'admin' || currentUserType === 'buyer'"
            role="buyer"
            :chat-data="buyer"
          />
          <trading-deal-message
            v-if="currentUserType === 'admin' || currentUserType === 'supplier'"
            role="supplier"
            :chat-data="supplier"
          />
          <b-card v-if="isDealMightBeClosed">
            <b-button
              class="aside-button"
              variant="primary"
              @click="closeDeal"
            >
              {{ $t('closeTheDeal') }}
            </b-button>
          </b-card>
          <b-card v-if="isDealMightBeProblem">
            <b-button
              class="aside-button"
              variant="primary"
              @click="problemPopupToggle"
            >
              {{ $t('needHelpWithDeal') }}
            </b-button>
          </b-card>
          <b-card v-if="isDealMightBeCloseProblem">
            <b-button
              class="aside-button"
              variant="primary"
              @click="solveProblem"
            >
              {{ $t('solveTheProblem') }}
            </b-button>
          </b-card>
        </b-col>
      </b-row>
      <trading-deal-problem-modal :is-show-modal.sync="problemPopup" />
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow, BCol, BCard, BButton, BOverlay, BSpinner,
} from 'bootstrap-vue';
import TradingNewCompany from '@/views/trading/TradingNewCompany.vue';
import TradingDealActions from '@/views/trading/trading-deals/TradingDealActions.vue';
import TradingDealMessage from '@/views/trading/trading-deals/TradingDealMessage.vue';
import TradingDealsProducts from '@/views/trading/trading-deals/TradingDealsProducts.vue';
import TradingDealComments from '@/views/trading/trading-deals/TradingDealComments.vue';
import TradingDealTransactions from '@/views/trading/trading-deals/transactions/TradingDealTransactions.vue';
import TradingDealGeneral from '@/views/trading/trading-deals/TradingDealGeneral.vue';
import DeliveryTypeAdmin from '@/views/trading/trading-deals/delivery/DeliveryTypeAdmin.vue';
import DeliveryTypeBuyer from '@/views/trading/trading-deals/delivery/DeliveryTypeBuyer.vue';
import { mapGetters } from 'vuex';
import TradingDealProblemModal from '@/views/trading/trading-deals/problem/TradingDealProblemModal.vue';
import TradingDealTracking from '@/views/trading/trading-deals/TradingDealTracking.vue';
import { dealStatuses } from '@/constants/deals.js';
import { getCurrencySymbol } from '@/constants/currency.js';

export default {
  components: {
    BSpinner,
    TradingDealProblemModal,
    BRow,
    BCol,
    BCard,
    BButton,
    BOverlay,
    TradingNewCompany,
    TradingDealActions,
    TradingDealMessage,
    TradingDealsProducts,
    TradingDealComments,
    TradingDealTransactions,
    TradingDealGeneral,
    DeliveryTypeAdmin,
    TradingDealTracking,
    DeliveryTypeBuyer,
  },
  data: () => ({
    dealStatuses: { ...dealStatuses },
    problemPopup: false,
  }),
  computed: {
    ...mapGetters({
      chatBuyerData: 'deal/chatBuyer',
      chatSupplierData: 'deal/chatSupplier',
    }),
    buyer() {
      return this.chatBuyerData;
    },
    supplier() {
      return this.chatSupplierData;
    },
    currentDeal() {
      return this.$store.state?.deal?.deal || null;
    },
    isLoading() {
      return this.$store.state.app.isLoading;
    },
    offer() {
      return this.$store.state.deal.deal?.offer;
    },
    requiredActions() {
      if (!this.currentDeal?.required_actions) {
        return [];
      }

      return this.currentDeal.required_actions[this.currentUserType];
    },
    currentStatus() {
      const text = this.dealStatuses[this.currentDeal?.status]?.name || '';
      const variant = this.dealStatuses[this.currentDeal?.status]?.variant || '';
      return { text, variant };
    },
    noteOwner() {
      if (!this.offer) {
        return {};
      }

      return {
        note: this.$store.state?.deal.deal?.offer?.note,
        user: {
          ...this.$store.state?.deal.deal?.offer?.owned_by?.profile,
          company_name: this.$store.state?.deal.deal?.offer?.owned_by?.company_name,
          date: this.$store.state?.deal.deal?.offer?.created_at,
          role: 'supplier',
        },
      };
    },
    noteRequest() {
      if (!this.currentDeal.request) {
        return {};
      }

      return {
        note: this.$store.state?.deal.deal?.request?.note,
        user: {
          ...this.$store.state?.deal.deal?.request?.owned_by?.profile,
          company_name: this.$store.state?.deal.deal?.request?.owned_by?.company_name,
          date: this.$store.state?.deal.deal?.deal?.created_at,
          role: 'buyer',
        },
      };
    },
    dealId() {
      return String(this.$route.params.id);
    },
    isDealMightBeClosed() {
      return this.currentDeal?.status === 5 && this.currentUserType === 'admin';
    },
    isDealMightBeProblem() {
      return this.currentDeal?.status !== 8;
    },
    isDealMightBeCloseProblem() {
      return this.currentDeal?.status === 8 && ['admin', 'buyer'].includes(this.currentUserType);
    },
    currentUserType() {
      return String(this.currentDeal?.user_role);
    },
    finalOfferCost() {
      return this.currentDeal.offer.legal_members[0].price;
    },
    fullOfferCost() {
      return this.currentDeal.price;
    },
    generalInformation() {
      const validUntil = this.currentDeal?.offer?.valid_until?.split('T')[0] || '';
      if (!this.currentDeal.offer) {
        return [];
      }
      if (this.currentUserType === 'admin') {
        return this.handleInformationForAdmin(validUntil);
      }
      if (this.currentUserType === 'buyer') {
        return this.handleInformationForBuyer(validUntil);
      }
      if (this.currentUserType === 'supplier') {
        return this.handleInformationForSupplier(validUntil);
      }
      return [];
    },
    currency() {
      return Number(this.offer?.currency);
    },
    supplierInfo() {
      return this.offer?.owned_by || null;
    },
    buyerInfo() {
      return this.currentDeal?.accepted_by || null;
    },
  },
  async created() {
    try {
      const promises = [
        this.$store.dispatch('deal/fetch', this.dealId),
        this.$store.dispatch('offers/fetchLegalOptions'),
      ];

      this.$store.dispatch('app/showLoader');

      await Promise.all(promises).finally(async () => {
        if (this.currentUserType !== 'admin') {
          this.$store.dispatch('app/hideLoader');
        } else {
          await this.$store.dispatch('offers/fetchLegalMembers', this.offer.id).finally(() => {
            this.$store.dispatch('app/hideLoader');
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    closeDeal() {
      this.$store.dispatch('deal/closeDeal', this.dealId);
    },
    solveProblem() {
      this.$store.dispatch('deal/solveProblem', this.dealId);
    },
    problemPopupToggle() {
      this.problemPopup = !this.problemPopup;
    },
    handleInformationForAdmin(validUntil) {
      const country = this.offer?.country;

      return [
        { label: `${this.$t('countryAndCode')}:`, value: `${country?.name} (${country?.code})` },
        { label: `${this.$t('validityPeriodOfTheOffer')}:`, value: validUntil },
        { label: `${this.$t('fullOfferCost')}:`, value: `${getCurrencySymbol(this.currency)}${this.fullOfferCost}` },
        { label: `${this.$t('finalOfferCost')}:`, value: `${getCurrencySymbol(this.currency)}${this.finalOfferCost}` },
      ];
    },
    handleInformationForBuyer(validUntil) {
      const country = this.offer?.country;

      return [
        { label: `${this.$t('countryAndCode')}:`, value: `${country?.name} (${country?.code})` },
        { label: `${this.$t('validityPeriodOfTheOffer')}:`, value: validUntil },
        { label: `${this.$t('fullOfferCost')}:`, value: `${getCurrencySymbol(this.currency)}${this.fullOfferCost}` },
        { label: `${this.$t('fullDeliveryCost')}:`, value: `${getCurrencySymbol(this.currency)}${this.currentDeal.delivery_price}` },
        { label: `${this.$t('fullCustomsCost')}:`, value: `${getCurrencySymbol(this.currency)}0` },
      ];
    },
    handleInformationForSupplier(validUntil) {
      return [
        { label: `${this.$t('validityPeriodOfTheOffer')}:`, value: validUntil },
        { label: `${this.$t('fullOfferCost')}:`, value: `${getCurrencySymbol(this.currency)}${this.fullOfferCost}` },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
.aside-button {
  width: 100%;
}

.loader-container {
  color: #7367f0;
  margin-top: 38vh;
  margin-left: 38vw;
}
</style>
