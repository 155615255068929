<template>
  <b-card no-body>
    <b-card-header class="pb-1">
      <div class="d-flex justify-content-between w-100">
        <div>
          <h4 class="d-inline-block mr-1 mb-0">
            {{ title }}
          </h4>
          <span class="offer-id mr-1">
            #{{ dealId }}
          </span>
        </div>
        <div>
          <div class="d-flex align-items-center justify-content-end">
            <b-badge
              :variant="status.variant"
              class="px-1"
            >
              {{ status.text }}
            </b-badge>
          </div>
        </div>
      </div>
    </b-card-header>
    <b-card-body>
      <hr class="divider mb-1" />
      <b-form-group
        v-if="!isDeal"
        :label="$t('countryOrCode')"
        label-for="country-field"
      >
        <b-form-input
          id="country-field"
          placeholder="exp. Canada"
        />
      </b-form-group>
      <div
        v-else
      >
        <div
          v-for="(item, index) in info"
          :key="index"
        >
          <span>{{ item.label }}</span>
          <span class="ml-1">
            {{ item.value }}
          </span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BFormGroup, BFormInput, BCard, BCardBody, BCardHeader, BBadge,
} from 'bootstrap-vue';

export default {
  components: {
    BFormGroup,
    BFormInput,
    BCard,
    BCardBody,
    BCardHeader,
    BBadge,
  },
  props: {
    title: {},
    status: {
      type: Object,
      default: () => {},
    },
    dealId: {
      type: String,
      default: '',
    },
    isDeal: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.divider {
  margin: 0;
}
.offer-id {
  font-weight: 500;
  color: $primary;
}
</style>
