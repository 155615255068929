<template>
  <b-card
    no-body
    :class="{'open': visible}"
    class="collapse-icon"
  >
    <b-card-header
      :class="{'collapsed': !visible}"
      :aria-expanded="visible ? 'true' : 'false'"
      aria-controls="transactions-collapse"
      role="tab"
      data-toggle="collapse"
      @click="updateVisible(!visible)"
    >
      <h4 class="d-inline-block mr-1 mb-0">
        {{ title }}
      </h4>
    </b-card-header>
    <b-collapse
      id="transactions-collapse"
      v-model="visible"
      role="tabpanel"
      accordion
    >
      <hr class="divider m-0" />
      <b-card-body v-if="userType === 'admin'">
        <div class="d-flex justify-content-between">
          <div class="transaction-chain">
            {{ $t('transactionsChain') }}
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <b-form-checkbox
              id="editChain"
              v-model="editToggle"
              switch
            />
            <label
              for="editChain"
              class="mb-0"
            >
              {{ $t('editChain') }}
            </label>
          </div>
        </div>
        <transactions-chain-admin
          v-show="editToggle"
          :transactions="transactions"
          @add-new="addNewTransactions"
          @remove-item="removeTransactions"
          @save-chain="editToggle = false"
        />
        <transactions-step-admin
          v-show="!editToggle"
          :deal="currentDeal"
          :transactions="transactions"
        />
      </b-card-body>
      <transactions-step-buyer
        v-if="userType === 'buyer'"
        :deal="currentDeal"
        @fetch-deal="fetchDeal"
      />
      <transactions-step-supplier
        v-if="userType === 'supplier'"
        :deal="currentDeal"
        @fetch-deal="fetchDeal"
      />
    </b-collapse>
  </b-card>
</template>

<script>
import {
  BCollapse, BCard, BCardBody, BCardHeader, BFormCheckbox,
} from 'bootstrap-vue';
import TransactionsChainAdmin from '@/views/trading/trading-deals/transactions/TransactionsChainAdmin.vue';
import TransactionsStepAdmin from '@/views/trading/trading-deals/transactions/TransactionsStepAdmin.vue';
import TransactionsStepBuyer from '@/views/trading/trading-deals/transactions/TransactionsStepBuyer.vue';
import TransactionsStepSupplier from '@/views/trading/trading-deals/transactions/TransactionsStepSupplier.vue';

export default {
  components: {
    BCollapse,
    BCard,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    TransactionsChainAdmin,
    TransactionsStepAdmin,
    TransactionsStepBuyer,
    TransactionsStepSupplier,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    userType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editToggle: true,
      visible: true,
    };
  },
  computed: {
    currentDeal() {
      return this.$store.state.deal.deal || {};
    },
    transactions() {
      return this.$store.state.deal.deal.offer.legal_members;
    },
  },
  created() {
    if (this.userType === 'admin') {
      this.editToggle = this.currentDeal.status > 1 ? !this.currentDeal.offer.legal_members[0].invoices.some(item => item.type === 'purchase') : true;
      const statusesToShow = [3, 4];
      if (statusesToShow.includes(this.currentDeal.status)) {
        this.updateVisible(false);
      }
    }
  },
  methods: {
    updateVisible(val = true) {
      this.visible = val;
      this.$emit('visible', val);
    },
    async fetchDeal() {
      await this.$store.dispatch('deal/fetch', this.currentDeal.id);
    },
    async addNewTransactions({ id }) {
      const newItem = {
        legal_id: id,
        price: this.currentDeal.price,
        margin: 0,
      };

      await this.$store.dispatch('offers/createNewOfferLegal', { id: this.currentDeal.offer.id, payload: newItem });
    },
    removeTransactions(id) {
      this.$store.dispatch('offers/deleteOfferLegal', { id, offerId: this.currentDeal.offer.id });
    },

  },
};
</script>
<style lang="scss" scoped>
.transaction-chain {
  font-size: 16px;
  font-weight: 700;
}
</style>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.transaction-item {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
  &-add-new {
    border: 1px dashed $table-border-color;
  }
}
.dark-layout {
  .transaction-item {
    border:  1px solid $theme-dark-widget-bg;
    &-add-new {
      border: 1px dashed $theme-dark-widget-bg;
    }
  }
}
</style>
