<template>
  <b-card-body>
    <template v-if="documents.length">
      <div v-if="supplyContract">
        <doc-section-header
          :header-text="String($t('supplyContract'))"
          :sub-header-text="String(supplyContract.type === 'supply_contract' ?
            $t('signDocumentAndUpload', {documentName: $t('supplyContract')})
            : $t('thisSignedDocument', {documentName: $t('supplyContract')}))"
          divider="after"
        >
          <template #content>
            <template v-if="supplyContract.type === 'supply_contract_signed'">
              <transactions-document-sign
                :document="supplyContract"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :is-signed="true"
                :is-removable="true"
                @fetch-deal="fetchDeal"
              />
            </template>
            <template v-else>
              <transactions-document-sign
                :document="supplyContract"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                @fetch-deal="fetchDeal"
              />
              <drop-zone
                class="section-margin"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :save-button-text="String($t('saveDocument'))"
                @add-file="saveSupplyContractSigned"
              />
            </template>
          </template>
        </doc-section-header>
      </div>

      <div v-if="packingDocument">
        <doc-section-header
          :header-text="String($t('packingInfo'))"
          :sub-header-text="String($t('infoPackingProducts'))"
          divider="after"
        >
          <template #content>
            <template v-if="packingDocument.type === 'packing_signed'">
              <transactions-document-sign
                :document="packingDocument"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :is-signed="true"
                :is-removable="true"
                @fetch-deal="fetchDeal"
              />
            </template>
            <template v-else>
              <transactions-document-sign
                :document="packingDocument"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                @fetch-deal="fetchDeal"
              />
              <drop-zone
                class="section-margin"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :save-button-text="String($t('saveDocument'))"
                @add-file="savePackingDocumentSigned"
              />
            </template>
          </template>
        </doc-section-header>
      </div>
    </template>
    <template v-else>
      <h5 class="section-margin">
        {{ $t('documentsWillHereSoon') }}
      </h5>
    </template>

    <template v-if="purchaseInvoice">
      <doc-section-header
        :header-text="String($t('payments'))"
        :sub-header-text="purchaseInvoice && purchaseInvoicePaid ? String($t('invoiceForPaymentToYouAndReceipt')) : String($t('invoiceForPaymentToYou'))"
        divider="after"
      >
        <template #content>
          <transactions-documents-list
            :documents="[purchaseInvoice]"
            :is-receipt="false"
            :is-removable="false"
            classes-list="margin-bottom-12"
            @fetch-deal="fetchDeal"
          />
          <transactions-documents-list
            v-if="purchaseInvoicePaid"
            :documents="[purchaseInvoicePaid]"
            :is-receipt="true"
            :is-removable="false"
            classes-list="margin-top-12"
            @fetch-deal="fetchDeal"
          />
          <div
            v-if="purchaseInvoice && purchaseInvoice.receipt_sent"
            class="d-flex justify-content-between section-margin"
          >
            <div class="d-flex align-items-center">
              <b-avatar
                size="32"
                variant="light-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                />
              </b-avatar>
              <h5 class="mb-0 ml-1">
                {{ $t('paymentSent') }}
              </h5>
            </div>
            <div v-if="!purchaseInvoice.invoice_paid">
              <b-button
                v-if="showReceivedBtn"
                class="mt-1"
                variant="primary"
                @click="paymentReceived"
              >
                {{ $t('paymentReceived') }}
              </b-button>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center"
              >
                <b-avatar
                  size="32"
                  variant="light-success"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </b-avatar>
                <h5 class="mb-0 ml-1">
                  {{ $t('paymentReceived') }}
                </h5>
              </div>
            </div>
          </div>
        </template>
      </doc-section-header>
    </template>

    <div>
      <div>
        <div>
          <div class="document-name">
            <span>{{ $t('otherDocuments') }}</span>
          </div>
          <transactions-document-sign
            v-for="(item, index) in documents.filter(doc => ![
              'packing',
              'packing_signed',
              'supply_contract',
              'supply_contract_signed',
              'purchase',
              'purchase_payment_receipt',
              'clearance',
              'clearance_payment_receipt',
              'delivery',
              'delivery_payment_receipt'
            ].includes(doc.type))"
            :key="index"
            :document="item"
            :is-removable="true"
            @fetch-deal="fetchDeal"
          />
        </div>

        <deal-other-docs
          document-type="transaction"
          class="mt-0 p-0"
        />
      </div>
    </div>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BButton, BAvatar,
} from 'bootstrap-vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import DocSectionHeader from '@/views/trading/trading-deals/components/DocSectionHeader.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';

export default {
  components: {
    TransactionsDocumentsList,
    DocSectionHeader,
    DropZone,
    DealOtherDocs,
    BCardBody,
    BButton,
    BAvatar,
    TransactionsDocumentSign,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    paramsToAppend: [{ key: 'stage', value: 'purchase' }],
  }),
  computed: {
    purchaseInvoice() {
      return this.deal.legal.invoices.find(item => item.type === 'purchase');
    },
    purchaseInvoicePaid() {
      return this.deal.legal.documents.find(item => item.type === 'purchase_payment_receipt');
    },
    documents() {
      return this.purchaseInvoice ? [...this.deal.legal.documents, this.purchaseInvoice] : [...this.deal.legal.documents];
    },
    showReceivedBtn() {
      return (this.purchaseInvoice && this.purchaseInvoice.receipt_sent) || this.deal.payment_sent;
    },
    supplyContract() {
      const supplyContractSigned = this.deal.legal.documents.find(
        item => item.type === 'supply_contract_signed',
      );

      if (supplyContractSigned) {
        return supplyContractSigned;
      }

      return this.deal.legal.documents.find(item => item.type === 'supply_contract');
    },
    packingDocument() {
      const packingDocumentSigned = this.deal.legal.documents.find(
        item => item.type === 'packing_signed',
      );

      if (packingDocumentSigned) {
        return packingDocumentSigned;
      }

      return this.deal.legal.documents.find(item => item.type === 'packing');
    },
  },
  methods: {
    paymentReceived() {
      this.$store.dispatch('deal/setPaymentReceived', { id: this.$route.params.id, payload: { stage: 'purchase' } });
    },
    fetchDeal() {
      this.$emit('fetch-deal');
    },
    saveSupplyContractSigned(files) {
      this.handleSaveDocument(files, 'supply_contract_signed', 'Signed supply contract');
    },
    savePackingDocumentSigned(files) {
      this.handleSaveDocument(files, 'packing_signed', 'Signed packing document');
    },
    async handleSaveDocument(files, type, meta = '') {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', type);
      formData.append('legal_id', String(this.$store.state.profile.profile.company.id));
      formData.append('meta', meta);
      formData.append('file', file);

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(() => {
        this.fetchDeal();
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
</style>

<style lang="scss" scoped>
.section-margin {
  margin-bottom: 21px;
}

.document-name {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}
</style>
