import i18n from '@/libs/i18n';

export const dealStatuses = {
  1: {
    name: i18n.t('processing'),
    variant: 'deal__processing',
  },
  2: {
    name: i18n.t('waitingForPayment'),
    variant: 'deal__waiting',
  },
  3: {
    name: i18n.t('equipment'),
    variant: 'deal__equipment',
  },
  4: {
    name: i18n.t('delivery'),
    variant: 'deal__delivery',
  },
  5: {
    name: i18n.t('goodsReceived'),
    variant: 'deal__received',
  },
  6: {
    name: i18n.t('deliveryIssue'),
    variant: 'deal__issue',
  },
  7: {
    name: i18n.t('closed'),
    variant: 'deal__closed',
  },
  8: {
    name: i18n.t('problem'),
    variant: 'deal__problem',
  },
};

export const getDealStatus = status => dealStatuses[status];

export const PRODUCTS_PACKING = 'products_packing';
export const PRODUCTS_SHIPPED_WAREHOUSE = 'products_shipped_warehouse';
export const COUNTRY_SUPPLIER = 'country_supplier';
export const COUNTRY_TRANSIT = 'country_transit';
export const COUNTRY_BUYER = 'country_buyer';
export const PRODUCTS_RECEIVED = 'products_received';

export const tracings = [
  PRODUCTS_PACKING,
  PRODUCTS_SHIPPED_WAREHOUSE,
  COUNTRY_SUPPLIER,
  COUNTRY_TRANSIT,
  COUNTRY_BUYER,
  PRODUCTS_RECEIVED,
];

export const getTrackingIndex = (deliveryStatus) => tracings.findIndex(val => val === deliveryStatus);
export const prevTrackingStep = (deliveryStatus) => tracings[getTrackingIndex(deliveryStatus) - 1] || null;
