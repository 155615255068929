const FILL_THE_CHAIN = 'fill_the_chain';
const SELECT_DELIVERY = 'select_delivery';
const SET_DELIVERY_PRICE = 'set_delivery_price';
const PAY_FOR_PRODUCTS = 'pay_for_products';
const CONFIRM_PRODUCTS_PAYMENT = 'confirm_products_payment';
const PAY_FOR_DELIVERY = 'pay_for_delivery';
const SELECT_CLEARANCE = 'select_clearance';
const SET_CLEARANCE_PRICE = 'sel_clearance_price';
const PAY_FOR_CLEARANCE = 'pay_for_clearance';
const CONFIRM_PRODUCTS_RECEIVING = 'confirm_products_receiving';
const CLOSE_DEAL = 'close_deal';
const SOLVE_PROBLEM = 'solve_problem';

export const prepareAction = action => ({
  icon: 'ArrowRightCircleIcon',
  variant: 'info',
  text: action.charAt(0).toUpperCase() + action.replaceAll('_', ' ').slice(1),
});
