<template>
  <b-card>
    <b-tabs
      v-if="transactions.length"
      v-model="selectedTabIndex"
      pills
      align="center"
    >
      <b-tab
        v-for="(transaction, index) in preparedTransactions"
        :key="transaction.id"
        title-item-class="tab-title"
      >
        <template #title>
          <span class="tab-title-text">
            {{ index + 1 }}
          </span>
        </template>
        <div class="tab-content p-1">
          <b-row class="align-items-center justify-content-center w-100">
            <b-col
              cols="12"
              lg="5"
            >
              <div class="d-flex align-items-center justify-content-center p-2 w-100">
                <b-avatar
                  size="40"
                  variant="light-primary"
                  class="mr-2"
                  :text="transaction.buyer_legal.company_name | avatarText"
                />
                <div>
                  <h6>{{ transaction.buyer_legal.company_name }}</h6>
                  <span class="company-card-country">
                    {{ $t(transaction.firstLinkChainName) }}
                  </span>
                </div>
              </div>
            </b-col>
            <b-col
              cols="12"
              lg="1"
              class="d-flex align-items-center justify-content-center"
            >
              <feather-icon
                class="d-none d-lg-block"
                icon="ChevronRightIcon"
                size="32"
              />
              <feather-icon
                class="d-lg-none"
                icon="ChevronDownIcon"
                size="32"
              />
            </b-col>
            <b-col
              v-if="transaction.seller_legal"
              cols="12"
              lg="5"
            >
              <div class="d-flex align-items-center justify-content-center p-2">
                <b-avatar
                  size="40"
                  variant="light-primary"
                  class="mr-2"
                  :text="transaction.seller_legal.company_name | avatarText"
                />
                <div>
                  <h6>{{ transaction.seller_legal.company_name }}</h6>
                  <span class="company-card-country">
                    {{ $t(transaction.lastLinkChainName) }}
                  </span>
                </div>
              </div>
            </b-col>
          </b-row>

          <template v-if="['buyer', 'supplier'].includes(currentLegalRole)">
            <div>
              <template v-if="legalDocuments.length">
                <div class="documents-price">
                  {{ getDocumentsTranslate(transaction.price, deal.offer.currency) }}
                </div>

                <hr />

                <div v-if="supplyContractForSign || supplyContractSigned">
                  <doc-section-header
                    :header-text="String($t('supplyContract'))"
                    :sub-header-text="subheaderSupplyContractText"
                    divider="after"
                    header-text-size="medium"
                    :show-checkbox="true"
                    :checked="viewStates.supplyContract[selectedTabIndex]"
                    :role="String($t(transaction.currentLegalRole))"
                    @checkbox-value-changed="checkboxValueChanged('supplyContract')"
                  >
                    <template #content>
                      <template v-if="supplyContractForSign && supplyContractSigned">
                        <transactions-document-sign
                          v-if="!viewStates.supplyContract[selectedTabIndex]"
                          :document="supplyContractForSign"
                          :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                          :is-signed="false"
                          :is-removable="false"
                          classes-list="margin-bottom-12"
                          @fetch-deal="fetchDeal"
                        />
                        <transactions-document-sign
                          :document="supplyContractSigned"
                          :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                          :is-signed="true"
                          :is-removable="true"
                          classes-list="margin-top-12"
                          @fetch-deal="fetchDeal"
                        />
                      </template>
                      <template v-else-if="supplyContractForSign">
                        <transactions-document-sign
                          :document="supplyContractForSign"
                          :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                          :classes-list="!viewStates.supplyContract[selectedTabIndex] ? 'margin-bottom-12' : ''"
                          @fetch-deal="fetchDeal"
                        />
                        <div v-if="viewStates.supplyContract[selectedTabIndex]">
                          <drop-zone
                            class="section-margin"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            :save-button-text="String($t('saveDocument'))"
                            @add-file="saveSupplyContractSigned"
                          />
                        </div>
                        <div v-else>
                          <document-not-uploaded
                            :document-name="String($t('supplyContract'))"
                            :document-type="String($t('signedDocument'))"
                            :role="String($t(transaction.currentLegalRole))"
                          />
                        </div>
                      </template>
                    </template>
                  </doc-section-header>
                </div>

                <template v-if="currentLegalRole === 'buyer'">
                  <div v-if="packingDocumentForSign">
                    <doc-section-header
                      :header-text="String($t('packingInfo'))"
                      :sub-header-text="subheaderPackingTextBuyer"
                      divider="after"
                      header-text-size="medium"
                      :show-checkbox="true"
                      :checked="viewStates.packing[selectedTabIndex]"
                      :role="String($t(transaction.currentLegalRole))"
                      @checkbox-value-changed="checkboxValueChanged('packing')"
                    >
                      <template #content>
                        <transactions-document-sign
                          :document="packingDocumentForSign"
                          :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                          @fetch-deal="fetchDeal"
                        />
                      </template>
                    </doc-section-header>
                  </div>
                </template>
                <template v-else>
                  <div v-if="packingDocumentForSign || packingDocumentSigned">
                    <doc-section-header
                      :header-text="String($t('packingInfo'))"
                      :sub-header-text="subheaderPackingTextSupplier"
                      divider="after"
                      header-text-size="medium"
                      :show-checkbox="true"
                      :checked="viewStates.packing[selectedTabIndex]"
                      :role="String($t(transaction.currentLegalRole))"
                      @checkbox-value-changed="checkboxValueChanged('packing')"
                    >
                      <template #content>
                        <template v-if="packingDocumentForSign && packingDocumentSigned">
                          <transactions-document-sign
                            v-if="!viewStates.packing[selectedTabIndex]"
                            :document="packingDocumentForSign"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            :is-signed="false"
                            :is-removable="false"
                            classes-list="margin-bottom-12"
                            @fetch-deal="fetchDeal"
                          />
                          <transactions-document-sign
                            :document="packingDocumentSigned"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            :is-signed="true"
                            :is-removable="true"
                            classes-list="margin-top-12"
                            @fetch-deal="fetchDeal"
                          />
                        </template>
                        <template v-else-if="packingDocumentForSign">
                          <transactions-document-sign
                            :document="packingDocumentForSign"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            @fetch-deal="fetchDeal"
                          />
                          <div v-if="viewStates.packing[selectedTabIndex]">
                            <drop-zone
                              class="section-margin"
                              :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                              :save-button-text="String($t('saveDocument'))"
                              @add-file="savePackingDocumentSigned"
                            />
                          </div>
                          <div v-else>
                            <document-not-uploaded
                              :document-name="String($t('packingInfo'))"
                              :document-type="String($t('signedDocument'))"
                              :role="String($t(transaction.currentLegalRole))"
                            />
                          </div>
                        </template>
                      </template>
                    </doc-section-header>
                  </div>
                </template>
              </template>
              <template v-else>
                <h5 class="section-margin">
                  {{ $t('documentsWillHereSoon') }}
                </h5>
              </template>

              <template v-if="currentLegalRole === 'buyer'">
                <template v-if="purchaseInvoicePaid || purchaseInvoiceForPayment">
                  <doc-section-header
                    :header-text="String($t('payments'))"
                    :sub-header-text="subheaderPaymentsTextBuyer"
                    divider="after"
                    header-text-size="medium"
                    :show-checkbox="true"
                    :checked="viewStates.purchaseInvoice[selectedTabIndex]"
                    :role="String($t(transaction.currentLegalRole))"
                    @checkbox-value-changed="checkboxValueChanged('purchaseInvoice')"
                  >
                    <template #content>
                      <template v-if="purchaseInvoicePaid && purchaseInvoiceForPayment">
                        <transactions-documents-list
                          v-if="!viewStates.purchaseInvoice[selectedTabIndex]"
                          :documents="[purchaseInvoiceForPayment]"
                          :is-receipt="false"
                          :is-removable="false"
                          classes-list="margin-bottom-12"
                          @fetch-deal="fetchDeal"
                        />
                        <transactions-documents-list
                          :documents="[purchaseInvoicePaid]"
                          :is-receipt="true"
                          :is-removable="deal.status === 2"
                          classes-list="margin-top-bottom-12"
                          @fetch-deal="fetchDeal"
                        />

                        <div
                          v-if="[2,3,4].includes(deal.status) && !isCurrentInvoiceSent && viewStates.purchaseInvoice[selectedTabIndex]"
                          class="section-margin"
                        >
                          <b-button
                            v-if="!isCurrentInvoiceSent"
                            class="mt-1"
                            variant="primary"
                            @click="sendPayment(transaction.id)"
                          >
                            {{ $t('paymentSent') }}
                          </b-button>
                        </div>
                      </template>
                      <template v-else-if="purchaseInvoiceForPayment">
                        <transactions-documents-list
                          :documents="[purchaseInvoiceForPayment]"
                          :is-receipt="false"
                          :is-removable="false"
                          @fetch-deal="fetchDeal"
                        />
                        <div v-if="viewStates.purchaseInvoice[selectedTabIndex]">
                          <drop-zone
                            class="section-margin"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            @add-file="savePaymentReceipt"
                          />
                        </div>
                        <div v-else>
                          <document-not-uploaded
                            :document-name="String($t('purchaseInvoice'))"
                            :document-type="String($t('receiptToDocument'))"
                            :role="String($t(transaction.currentLegalRole))"
                          />
                        </div>
                      </template>

                      <div
                        v-if="isCurrentInvoiceSent"
                        class="d-flex justify-content-between section-margin"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            size="32"
                            variant="light-success"
                          >
                            <feather-icon
                              icon="CheckCircleIcon"
                            />
                          </b-avatar>
                          <h5 class="mb-0 ml-1">
                            {{ $t('paymentSent') }}
                          </h5>
                        </div>
                        <div>
                          <template v-if="!viewStates.purchaseInvoice[selectedTabIndex]">
                            <template v-if="!isCurrentInvoicePaid">
                              <b-button
                                variant="primary"
                                @click="paymentReceived(transaction.id)"
                              >
                                {{ $t('paymentReceived') }}
                              </b-button>
                            </template>
                            <template v-else>
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  size="32"
                                  variant="light-success"
                                >
                                  <feather-icon
                                    icon="CheckCircleIcon"
                                  />
                                </b-avatar>
                                <h5 class="mb-0 ml-1">
                                  {{ $t('paymentReceived') }}
                                </h5>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </doc-section-header>
                </template>
              </template>
              <template v-else>
                <template v-if="purchaseInvoicePaid || purchaseInvoiceForPayment">
                  <doc-section-header
                    :header-text="String($t('payments'))"
                    :sub-header-text="subheaderPaymentsTextSupplier"
                    divider="after"
                    header-text-size="medium"
                    :show-checkbox="true"
                    :checked="viewStates.purchaseInvoice[selectedTabIndex]"
                    :role="String($t(transaction.currentLegalRole))"
                    @checkbox-value-changed="checkboxValueChanged('purchaseInvoice')"
                  >
                    <template #content>
                      <template v-if="purchaseInvoicePaid && purchaseInvoiceForPayment">
                        <transactions-documents-list
                          :documents="[purchaseInvoiceForPayment]"
                          :is-receipt="false"
                          :is-removable="false"
                          classes-list="margin-bottom-12"
                          @fetch-deal="fetchDeal"
                        />
                        <transactions-documents-list
                          :documents="[purchaseInvoicePaid]"
                          :is-receipt="true"
                          :is-removable="deal.status === 2 && !viewStates.purchaseInvoice[selectedTabIndex]"
                          classes-list="margin-top-12"
                          @fetch-deal="fetchDeal"
                        />

                        <div
                          v-if="[2,3,4].includes(deal.status) && !isCurrentInvoiceSent && !viewStates.purchaseInvoice[selectedTabIndex]"
                          class="section-margin"
                        >
                          <b-button
                            v-if="!isCurrentInvoiceSent"
                            class="mt-1"
                            variant="primary"
                            @click="sendPayment(transaction.id)"
                          >
                            {{ $t('paymentSent') }}
                          </b-button>
                        </div>
                      </template>
                      <template v-else-if="purchaseInvoiceForPayment">
                        <transactions-documents-list
                          :documents="[purchaseInvoiceForPayment]"
                          :is-receipt="false"
                          :is-removable="false"
                          @fetch-deal="fetchDeal"
                        />
                        <div v-if="!viewStates.purchaseInvoice[selectedTabIndex]">
                          <drop-zone
                            class="section-margin"
                            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                            @add-file="savePaymentReceipt"
                          />
                        </div>
                      </template>

                      <div
                        v-if="isCurrentInvoiceSent"
                        class="d-flex justify-content-between section-margin"
                      >
                        <div class="d-flex align-items-center">
                          <b-avatar
                            size="32"
                            variant="light-success"
                          >
                            <feather-icon
                              icon="CheckCircleIcon"
                            />
                          </b-avatar>
                          <h5 class="mb-0 ml-1">
                            {{ $t('paymentSent') }}
                          </h5>
                        </div>
                        <div>
                          <template v-if="viewStates.purchaseInvoice[selectedTabIndex]">
                            <template v-if="!isCurrentInvoicePaid">
                              <b-button
                                variant="primary"
                                @click="paymentReceived(transaction.id)"
                              >
                                {{ $t('paymentReceived') }}
                              </b-button>
                            </template>
                            <template v-else>
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  size="32"
                                  variant="light-success"
                                >
                                  <feather-icon
                                    icon="CheckCircleIcon"
                                  />
                                </b-avatar>
                                <h5 class="mb-0 ml-1">
                                  {{ $t('paymentReceived') }}
                                </h5>
                              </div>
                            </template>
                          </template>
                        </div>
                      </div>
                    </template>
                  </doc-section-header>
                </template>
              </template>

              <div>
                <div>
                  <div class="document-name">
                    <span>{{ $t('otherDocuments') }}</span>
                  </div>
                  <transactions-document-sign
                    v-for="(item, itemIndex) in legalDocuments.filter(doc => ![
                      'packing',
                      'packing_signed',
                      'supply_contract',
                      'supply_contract_signed',
                      'purchase',
                      'purchase_payment_receipt',
                      'clearance',
                      'clearance_payment_receipt',
                      'delivery',
                      'delivery_payment_receipt'
                    ].includes(doc.type))"
                    :key="itemIndex"
                    :document="item"
                    :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                    :is-removable="true"
                    :classes-list="itemIndex ? 'margin-top-bottom-12' : 'margin-bottom-12'"
                    @fetch-deal="fetchDeal"
                  />
                </div>

                <deal-other-docs
                  :legal="transaction"
                  :deal-id="String(deal.id)"
                  :owner-id="String(currentLegalUserId)"
                  document-type="transaction"
                  class="mt-0 p-0"
                />
              </div>
            </div>
          </template>
          <template v-else>
            <div>
              <div>
                <div class="document-name">
                  <span>{{ $t('intermediaryDocuments') }}</span>
                </div>
                <transactions-document-sign
                  v-for="(item, itemIndex) in allIntermediaryDocuments"
                  :key="itemIndex"
                  :document="item"
                  :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                  :is-removable="true"
                  :classes-list="index ? 'margin-top-bottom-12' : 'margin-bottom-12'"
                  @fetch-deal="fetchDeal"
                />
              </div>

              <deal-other-docs
                :legal="transaction"
                :deal-id="String(deal.id)"
                document-type="transaction"
                class="mt-2 p-0"
              />
            </div>
          </template>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard, BTab, BTabs, BAvatar, BRow, BCol, BButton,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';
import { getCurrencySymbol } from '@/constants/currency';
import DocSectionHeader from '@/views/trading/trading-deals/components/DocSectionHeader.vue';
import DocumentNotUploaded from '@/views/trading/trading-deals/components/DocumentNotUploaded.vue';

export default {
  components: {
    DocSectionHeader,
    DealOtherDocs,
    BCard,
    BTab,
    BTabs,
    BAvatar,
    BRow,
    BCol,
    BButton,
    DropZone,
    TransactionsDocumentsList,
    TransactionsDocumentSign,
    DocumentNotUploaded,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      url: 'localhost',
    },
    selectedTabIndex: 0,
    viewStates: {
      supplyContract: {},
      packing: {},
      purchaseInvoice: {},
      otherDocuments: {},
    },
  }),
  computed: {
    legals() {
      return this.$store.state.deal.deal.offer.legal_members;
    },
    offer() {
      return this.deal?.offer;
    },
    dealPrice() {
      return this.deal?.offer?.price || 0;
    },
    supplier() {
      return this.offer?.created_by || null;
    },
    buyer() {
      return this.deal?.accepted_by || null;
    },
    preparedTransactions() {
      return this.transactions.map((transaction, index) => {
        let intermediaryIndex = null;

        const isFirstIndex = index === 0;
        const isLastIndex = index + 1 === this.transactions.length;

        if (!isFirstIndex && !isLastIndex) {
          intermediaryIndex = index;
        }

        return {
          ...transaction,
          firstLinkChainName: isFirstIndex ? 'buyer' : 'intermediary',
          lastLinkChainName: isLastIndex ? 'supplier' : 'intermediary',
          intermediaryIndex,
          currentLegalRole: (() => {
            if (isFirstIndex) {
              return 'buyer';
            }

            if (isLastIndex) {
              return 'supplier';
            }

            return 'intermediary';
          })(),
          buyerAvatarUrl: isFirstIndex ? this.buyer?.profile?.avatar_url : null,
          supplierAvatarUrl: isLastIndex ? this.supplier?.profile?.avatar_url : null,
        };
      });
    },
    legalDocuments() {
      const invoices = this.legals[this.selectedTabIndex].invoices
        .filter(invoice => !['delivery', 'clearance'].includes(invoice.type));
      return [
        ...this.legals[this.selectedTabIndex].documents,
        ...invoices,
      ];
    },
    allIntermediaryDocuments() {
      return [
        ...this.legals[this.selectedTabIndex].invoices,
        ...this.legals[this.selectedTabIndex].documents,
      ];
    },
    supplyContractForSign() {
      return this.legals[this.selectedTabIndex].documents.find(item => item.type === 'supply_contract');
    },
    supplyContractSigned() {
      return this.legals[this.selectedTabIndex].documents.find(item => item.type === 'supply_contract_signed');
    },
    packingDocumentForSign() {
      return this.legals[this.selectedTabIndex].documents.find(doc => doc.type === 'packing');
    },
    packingDocumentSigned() {
      return this.legals[this.selectedTabIndex].documents.find(item => item.type === 'packing_signed');
    },
    purchaseInvoiceForPayment() {
      return this.legals[this.selectedTabIndex].invoices.find(item => item.type === 'purchase');
    },
    purchaseInvoicePaid() {
      return this.legals[this.selectedTabIndex].documents.find(item => item.type === 'purchase_payment_receipt');
    },

    isCurrentInvoicePaid() {
      return this.legalDocuments.find(invoice => invoice.type === 'purchase')?.invoice_paid || false;
    },
    isCurrentInvoiceSent() {
      return this.legalDocuments.find(invoice => invoice.type === 'purchase')?.receipt_sent || false;
    },
    legalReceipts() {
      const currentLegalInvoices = this.legals[this.selectedTabIndex]?.invoices || [];

      return [currentLegalInvoices.find(invoice => invoice.type === 'purchase')];
    },
    currentLegalRole() {
      return this.preparedTransactions[this.selectedTabIndex].currentLegalRole;
    },
    currentLegalUserId() {
      if (this.currentLegalRole === 'buyer') {
        return this.buyer?.id;
      }

      if (this.currentLegalRole === 'supplier') {
        return this.supplier?.id;
      }

      return '';
    },
    subheaderSupplyContractText() {
      if (['buyer', 'supplier'].includes(this.currentLegalRole)) {
        if (this.viewStates.supplyContract[this.selectedTabIndex]) {
          if (this.supplyContractSigned) {
            return String(this.$t('thisSignedDocument', { documentName: this.$t('supplyContract') }));
          }
          return String(this.$t('signDocumentAndUpload', { documentName: this.$t('supplyContract') }));
        }
        if (this.supplyContractSigned) {
          return String(this.$t('thisGeneratedSignedContract', { documentName: this.$t('supplyContract'), role: this.$t(this.currentLegalRole) }));
        }
        return String(this.$t('thisGeneratedForSign', { documentName: this.$t('supplyContract'), role: this.$t(this.currentLegalRole) }));
      }
      return '';
    },
    subheaderPackingTextBuyer() {
      if (this.viewStates.packing[this.selectedTabIndex]) {
        return String(this.$t('infoPackingProducts'));
      }
      return String(this.$t('documentForReview', { documentName: this.$t('packingInfo'), role: this.$t(this.currentLegalRole) }));
    },
    subheaderPackingTextSupplier() {
      if (this.viewStates.packing[this.selectedTabIndex]) {
        if (this.packingDocumentSigned) {
          return String(this.$t('thisSignedDocument', { documentName: this.$t('packingInfo') }));
        }
        return String(this.$t('signDocumentAndUpload', { documentName: this.$t('packingInfo') }));
      }
      if (this.packingDocumentSigned) {
        return String(this.$t('thisGeneratedSignedContract', { documentName: this.$t('packingInfo'), role: this.$t(this.currentLegalRole) }));
      }
      return String(this.$t('thisGeneratedForSign', { documentName: this.$t('packingInfo'), role: this.$t(this.currentLegalRole) }));
    },
    subheaderPaymentsTextBuyer() {
      if (this.viewStates.purchaseInvoice[this.selectedTabIndex]) {
        if (this.purchaseInvoicePaid) {
          return String(this.$t('paymentReceiptToDocument', { documentName: this.$t('purchaseInvoice') }));
        }
        return String(this.$t('payInvoiceAndUploadReceipt', { documentName: this.$t('purchaseInvoice') }));
      }
      if (this.purchaseInvoicePaid) {
        return String(this.$t('thisInvoiceAndReceipt', { documentName: this.$t('purchaseInvoice'), role: this.$t(this.currentLegalRole) }));
      }
      return String(this.$t('documentForPayment', { documentName: this.$t('purchaseInvoice'), role: this.$t(this.currentLegalRole) }));
    },
    subheaderPaymentsTextSupplier() {
      if (this.viewStates.purchaseInvoice[this.selectedTabIndex]) {
        if (this.purchaseInvoicePaid) {
          return String(this.$t('invoiceForPaymentToYouAndReceipt'));
        }
        return String(this.$t('invoiceForPaymentToYou'));
      }
      if (this.purchaseInvoicePaid) {
        return String(this.$t('paymentReceiptToDocument', { documentName: this.$t('purchaseInvoice') }));
      }
      return String(this.$t('payInvoiceAndUploadReceipt', { documentName: this.$t('purchaseInvoice') }));
    },
  },
  methods: {
    async fetchDeal() {
      await this.$store.dispatch('deal/fetch', this.deal.id);
    },
    getDocumentsTranslate(price, currency) {
      return this.$t('documentsTotal', { cost: `${price}${getCurrencySymbol(currency)}` });
    },
    async savePaymentReceipt(files) {
      const needSendPayment = !!this.purchaseInvoiceForPayment?.receipt_sent;

      await this.handleSaveDocument(files, 'purchase_payment_receipt', 'purchase payment receipt');

      if (needSendPayment) {
        await this.sendPayment(this.legals[this.selectedTabIndex].id);
      }
    },
    async sendPayment(legalId) {
      try {
        const { data } = await this.$store.dispatch('deal/getDocument', this.purchaseInvoicePaid.id);
        const formData = new FormData();

        formData.append('receipt', data);
        formData.append('stage', 'purchase');
        formData.append('legal_id', legalId);

        await this.$store.dispatch('deal/setPaymentSent', { payload: formData, id: this.$route.params.id });
      } catch (e) {
        console.log(e);
      }
    },
    async paymentReceived(legalId) {
      try {
        await this.$store.dispatch('deal/setPaymentReceived', {
          id: this.$route.params.id,
          payload: { stage: 'purchase', legal_id: legalId },
        });
      } catch (e) {
        console.log(e);
      }
    },
    saveSupplyContractSigned(files) {
      this.handleSaveDocument(files, 'supply_contract_signed', 'Signed supply contract');
    },
    savePackingDocumentSigned(files) {
      this.handleSaveDocument(files, 'packing_signed', 'Signed packing document');
    },
    async handleSaveDocument(files, type, meta = '') {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', type);
      formData.append('link_id', String(this.legals[this.selectedTabIndex].id));
      formData.append('meta', meta);
      formData.append('file', file);

      if (this.currentLegalUserId) formData.append('owner_id', String(this.currentLegalUserId));

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        await this.fetchDeal();
      });
    },
    checkboxValueChanged(sectionType) {
      this.viewStates[sectionType][this.selectedTabIndex] = !this.viewStates[sectionType][this.selectedTabIndex];
      this.viewStates = { ...this.viewStates };
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.tab-content {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
}
.tab-title {
  margin: 0 0.5rem;
}
.tab-title .nav-link {
  background-color: $table-border-color;
  .tab-title-text {
    border: 2px solid $headings-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 23px;
    height: 23px;
  }
  &.active .tab-title-text {
    border: 2px solid #ffffff;
  }
}
.dark-layout {
  .tab-title .nav-link {
    background-color: $theme-dark-widget-bg;
    .tab-title-text {
      border: 2px solid $theme-dark-headings-color;
    }
  }
  .tab-content {
    border:  1px solid $theme-dark-widget-bg;
  }
}
</style>
<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';

.documents-price {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}

.section-margin {
  margin-bottom: 21px;
}

.document-name {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}
</style>
