<template>
  <div>
    <b-card
      no-body
      :class="{'open': isDeliveryVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isDeliveryVisible}"
        :aria-expanded="isDeliveryVisible ? 'true' : 'false'"
        aria-controls="delivery-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateDeliveryVisible(!isDeliveryVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('delivery') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="delivery-collapse"
        v-model="isDeliveryVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <div>
            <b-row>
              <b-col
                v-for="delivery in options.delivery"
                :key="delivery.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :variant="delivery"
                  :selected-variant="selectedDelivery"
                  :class="{
                    'disable-card': deliveryInvoiceForPayment && deliveryInvoiceForPayment.receipt_sent && delivery.id === 2
                      || dealCurrentStatus > 2 && selectedDelivery !== delivery.id,
                    'no-selectable': dealCurrentStatus > 2
                  }"
                  @select="selectDelivery"
                />
              </b-col>
            </b-row>
            <div v-if="selectedDelivery === 1">
              <template v-if="!deliveryInvoice">
                <b-form-group
                  class="mt-2"
                  :label="$t('deliveryCost')"
                  label-for="delivery-cost"
                >
                  <b-row class="d-flex align-items-center">
                    <b-col cols="2">
                      <b-form-input
                        id="delivery-cost"
                        v-model="delivery_cost"
                        type="number"
                        @focusin="inputNumberFocus('in', 'delivery_cost')"
                        @focusout="inputNumberFocus('out', 'delivery_cost')"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="p-0"
                    >
                      <span>
                        {{ currency | currencySymbol }}
                      </span>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-button
                      class="mt-2"
                      variant="primary"
                      :disabled="!delivery_cost"
                      @click="generateDeliveryInvoice"
                    >
                      {{ $t('generateDeliveryInvoice') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <div>
                <doc-section-header
                  v-if="deliveryInvoice"
                  :header-text="String($t('payments'))"
                  :sub-header-text="subheaderDeliveryText"
                  divider="after"
                  class="mt-2"
                  header-text-size="medium"
                  :show-checkbox="true"
                  :checked="buyerMode.delivery"
                  :role="String($t('buyer'))"
                  @checkbox-value-changed="checkboxValueChanged('delivery')"
                >
                  <template #content>
                    <template v-if="!deliveryInvoiceIsReceipt">
                      <transactions-documents-list
                        :documents="[deliveryInvoice]"
                        :is-receipt="deliveryInvoiceIsReceipt"
                        :is-removable="false"
                        @fetch-deal="fetchDeal"
                      />

                      <template v-if="buyerMode.delivery">
                        <drop-zone
                          class="mb-2"
                          @add-file="addDeliveryInvoice"
                        />
                      </template>
                      <template v-else>
                        <document-not-uploaded
                          :document-name="String($t('deliveryInvoice'))"
                          :document-type="String($t('receiptToDocument'))"
                          :role="String($t('buyer'))"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <transactions-documents-list
                        v-if="!buyerMode.delivery"
                        :documents="[deliveryInvoiceForPayment]"
                        :is-receipt="false"
                        :is-removable="false"
                        classes-list="margin-bottom-12"
                        @fetch-deal="fetchDeal"
                      />
                      <transactions-documents-list
                        :documents="[deliveryInvoice]"
                        :is-receipt="true"
                        :is-removable="dealCurrentStatus === 2 && (buyerMode.delivery && !deliveryInvoiceForPayment.receipt_sent || !buyerMode.delivery)"
                        :classes-list="buyerMode.delivery ? 'margin-top-bottom-12' : 'margin-top-12'"
                        @fetch-deal="fetchDeal"
                      />
                    </template>

                    <template v-if="deliveryInvoiceForPayment && !deliveryInvoiceForPayment.receipt_sent">
                      <b-button
                        v-if="buyerMode.delivery"
                        class="mt-1 section-margin"
                        variant="primary"
                        :disabled="!deliveryInvoiceIsReceipt"
                        @click="sendDelivery"
                      >
                        {{ $t('paymentSent') }}
                      </b-button>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-items-center section-margin"
                        :class="{ 'margin-top-12' : !deliveryInvoiceIsReceipt }"
                      >
                        <b-avatar
                          size="32"
                          variant="light-success"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                          />
                        </b-avatar>
                        <h5 class="mb-0 ml-1">
                          {{ $t('deliveryPaymentSent') }}
                        </h5>
                      </div>
                    </template>

                    <!--                    <b-row v-if="isDeliveryInvoiceReady">-->
                    <!--                      <b-col-->
                    <!--                        cols="12"-->
                    <!--                        md="4"-->
                    <!--                      >-->
                    <!--                        <b-button-->
                    <!--                          class="mt-2"-->
                    <!--                          variant="primary"-->
                    <!--                          :disabled="deliverySendingToBuyer"-->
                    <!--                          @click="sendDeliveryInvoiceToBuyer"-->
                    <!--                        >-->
                    <!--                          {{ $t('sendInvoiceToBuyer') }}-->
                    <!--                        </b-button>-->
                    <!--                      </b-col>-->
                    <!--                    </b-row>-->
                  </template>
                </doc-section-header>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-card
      no-body
      :class="{'open': isClearanceVisible}"
      class="collapse-icon"
    >
      <b-card-header
        :class="{'collapsed': !isClearanceVisible}"
        :aria-expanded="isClearanceVisible ? 'true' : 'false'"
        aria-controls="clearance-collapse"
        role="tab"
        data-toggle="collapse"
        @click="updateClearanceVisible(!isClearanceVisible)"
      >
        <h4 class="d-inline-block mr-1 mb-0">
          {{ $t('customs') }}
        </h4>
      </b-card-header>
      <b-collapse
        id="clearance-collapse"
        v-model="isClearanceVisible"
        role="tabpanel"
        accordion
      >
        <hr class="divider m-0" />
        <b-card-body>
          <div>
            <b-row>
              <b-col
                v-for="custom in options.customs"
                :key="custom.id"
                md="6"
                lg="6"
                cols="12"
              >
                <delivery-card-variant
                  :variant="custom"
                  :disabled="selectedDelivery === 2"
                  :selected-variant="selectedCustom"
                  :class="{
                    'disable-card': clearanceInvoiceForPayment && clearanceInvoiceForPayment.receipt_sent && custom.id === 2
                      || dealCurrentStatus > 2 && selectedCustom !== custom.id,
                    'no-selectable': dealCurrentStatus > 2
                  }"
                  @select="selectCustom"
                />
              </b-col>
            </b-row>
            <div v-if="selectedCustom === 1">
              <template v-if="!clearanceInvoice">
                <b-form-group
                  class="mt-2"
                  :label="$t('clearanceCost')"
                  label-for="custom-cost"
                >
                  <b-row class="d-flex align-items-center">
                    <b-col cols="2">
                      <b-form-input
                        id="custom-cost"
                        v-model="custom_cost"
                        type="number"
                        @focusin="inputNumberFocus('in', 'custom_cost')"
                        @focusout="inputNumberFocus('out', 'custom_cost')"
                      />
                    </b-col>
                    <b-col
                      cols="2"
                      class="p-0"
                    >
                      <span>
                        {{ currency | currencySymbol }}
                      </span>
                    </b-col>
                  </b-row>
                </b-form-group>
                <b-row>
                  <b-col
                    cols="12"
                    md="5"
                  >
                    <b-button
                      class="mt-2"
                      variant="primary"
                      :disabled="!custom_cost"
                      @click="generateClearanceInvoice"
                    >
                      {{ $t('generateCustomsInvoice') }}
                    </b-button>
                  </b-col>
                </b-row>
              </template>

              <div>
                <doc-section-header
                  v-if="clearanceInvoice"
                  :header-text="String($t('payments'))"
                  :sub-header-text="subheaderClearanceText"
                  divider="after"
                  class="mt-2"
                  header-text-size="medium"
                  :show-checkbox="true"
                  :checked="buyerMode.clearance"
                  :role="String($t('buyer'))"
                  @checkbox-value-changed="checkboxValueChanged('clearance')"
                >
                  <template #content>
                    <template v-if="!clearanceInvoiceIsReceipt">
                      <transactions-documents-list
                        :documents="[clearanceInvoice]"
                        :is-receipt="clearanceInvoiceIsReceipt"
                        :is-removable="false"
                        @fetch-deal="fetchDeal"
                      />
                      <template v-if="buyerMode.clearance">
                        <drop-zone
                          class="mb-2"
                          @add-file="addClearanceInvoice"
                        />
                      </template>
                      <template v-else>
                        <document-not-uploaded
                          :document-name="String($t('clearanceInvoice'))"
                          :document-type="String($t('receiptToDocument'))"
                          :role="String($t('buyer'))"
                        />
                      </template>
                    </template>
                    <template v-else>
                      <transactions-documents-list
                        v-if="!buyerMode.clearance"
                        :documents="[clearanceInvoiceForPayment]"
                        :is-receipt="false"
                        :is-removable="false"
                        classes-list="margin-bottom-12"
                        @fetch-deal="fetchDeal"
                      />
                      <transactions-documents-list
                        :documents="[clearanceInvoice]"
                        :is-receipt="true"
                        :is-removable="dealCurrentStatus === 2 && (buyerMode.clearance && !clearanceInvoiceForPayment.receipt_sent || !buyerMode.clearance)"
                        :classes-list="buyerMode.clearance ? 'margin-top-bottom-12' : 'margin-top-12'"
                        @fetch-deal="fetchDeal"
                      />
                    </template>

                    <template v-if="clearanceInvoiceForPayment && !clearanceInvoiceForPayment.receipt_sent">
                      <b-button
                        v-if="buyerMode.clearance"
                        class="mt-1 section-margin"
                        variant="primary"
                        :disabled="!clearanceInvoiceIsReceipt"
                        @click="sendClearance"
                      >
                        {{ $t('customsPaymentSent') }}
                      </b-button>
                    </template>
                    <template v-else>
                      <div
                        class="d-flex align-items-center section-margin"
                        :class="{ 'margin-top-12' : !clearanceInvoiceIsReceipt }"
                      >
                        <b-avatar
                          size="32"
                          variant="light-success"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                          />
                        </b-avatar>
                        <h5 class="mb-0 ml-1">
                          {{ $t('customsPaymentSent') }}
                        </h5>
                      </div>
                    </template>

                    <!--                    <b-row v-if="isCustomInvoiceReady">-->
                    <!--                      <b-col-->
                    <!--                        cols="12"-->
                    <!--                        md="4"-->
                    <!--                      >-->
                    <!--                        <b-button-->
                    <!--                          class="mt-2"-->
                    <!--                          variant="primary"-->
                    <!--                          :disabled="customsSendingToBuyer"-->
                    <!--                          @click="sendCustomsInvoiceToBuyer"-->
                    <!--                        >-->
                    <!--                          {{ $t('sendInvoiceToBuyer') }}-->
                    <!--                        </b-button>-->
                    <!--                      </b-col>-->
                    <!--                    </b-row>-->
                  </template>
                </doc-section-header>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
  </div>
</template>

<script>
import {
  BCardBody, BButton, BRow, BCol, BFormGroup, BFormInput, BAvatar, BCardHeader, BCollapse, BCard,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import DeliveryCardVariant from '@/views/trading/trading-deals/delivery/DeliveryCardVariant.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import DocSectionHeader from '@/views/trading/trading-deals/components/DocSectionHeader.vue';
import DocumentNotUploaded from '@/views/trading/trading-deals/components/DocumentNotUploaded.vue';

export default {
  components: {
    DocumentNotUploaded,
    DocSectionHeader,
    DropZone,
    BCardBody,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    TransactionsDocumentsList,
    DeliveryCardVariant,
    BAvatar,
    BCardHeader,
    BCollapse,
    BCard,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    currency: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isDeliveryInvoiceReady: false,
      isCustomInvoiceReady: false,
      delivery_cost: null,
      custom_cost: null,
      selectedDelivery: null,
      selectedCustom: null,
      deliverySendingToBuyer: false,
      customsSendingToBuyer: false,
      isDeliveryVisible: true,
      isClearanceVisible: true,
      buyerMode: {
        delivery: false,
        clearance: false,
      },
    };
  },
  computed: {
    options() {
      return {
        delivery: [
          { icon: 'ShoppingCartIcon', name: this.$t('buyerDelivers'), id: 2 },
          { icon: 'TruckIcon', name: this.$t('weArrangeDeliver'), id: 1 },
        ],
        customs: [
          { icon: 'BriefcaseIcon', name: this.$t('customsByBuyer'), id: 2 },
          { icon: 'GiftIcon', name: this.$t('weArrangeCustoms'), id: 1 },
        ],
      };
    },
    currentRoute() {
      const { id } = this.$route.params;
      return id || '';
    },
    currentDeal() {
      return this.$store.state.deal.deal;
    },
    allInvoices() {
      return this.$store.state.deal.deal.offer.legal_members.reduce((acc, member) => [...acc, ...member.invoices], []);
    },
    isDeliveryPaymentSent() {
      const [invoice] = this.deliveryDocuments;
      return invoice?.receipt_sent;
    },
    isCustomsPaymentSent() {
      const [invoice] = this.customsDocuments;
      return invoice?.receipt_sent;
    },
    deliveryDocuments() {
      return this.allInvoices?.filter(item => item.type === 'delivery') || [];
    },
    customsDocuments() {
      return this.allInvoices?.filter(item => item.type === 'clearance') || [];
    },
    dealCurrentStatus() {
      return this.$store.state.deal.deal.status;
    },
    buyerLegalMember() {
      return this.$store.state.deal.deal.offer.legal_members[0];
    },
    buyerLegalId() {
      return this.buyerLegalMember?.id;
    },
    buyerUserId() {
      return this.$store.state.deal.deal?.accepted_by?.id;
    },
    buyerDeliveryAndCustomsDocuments() {
      const invoicesForPayment = this.buyerLegalMember.invoices
        .filter(invoice => ['delivery', 'clearance'].includes(invoice.type));
      const paidInvoices = this.buyerLegalMember.documents
        .filter(invoice => ['delivery_payment_receipt', 'clearance_payment_receipt'].includes(invoice.type));

      return [
        ...paidInvoices,
        ...invoicesForPayment,
      ];
    },
    deliveryInvoice() {
      const deliveryInvoiceReceipt = this.buyerDeliveryAndCustomsDocuments.find(
        item => item.type === 'delivery_payment_receipt',
      );

      if (deliveryInvoiceReceipt) {
        return deliveryInvoiceReceipt;
      }

      return this.buyerDeliveryAndCustomsDocuments.find(item => item.type === 'delivery');
    },
    deliveryInvoiceForPayment() {
      return this.buyerDeliveryAndCustomsDocuments.find(item => item.type === 'delivery');
    },
    deliveryInvoiceIsReceipt() {
      return this.deliveryInvoice?.type === 'delivery_payment_receipt';
    },
    clearanceInvoice() {
      const clearanceInvoiceReceipt = this.buyerDeliveryAndCustomsDocuments.find(
        item => item.type === 'clearance_payment_receipt',
      );

      if (clearanceInvoiceReceipt) {
        return clearanceInvoiceReceipt;
      }

      return this.buyerDeliveryAndCustomsDocuments.find(item => item.type === 'clearance');
    },
    clearanceInvoiceForPayment() {
      return this.buyerDeliveryAndCustomsDocuments.find(item => item.type === 'clearance');
    },
    clearanceInvoiceIsReceipt() {
      return this.clearanceInvoice?.type === 'clearance_payment_receipt';
    },
    subheaderDeliveryText() {
      if (this.buyerMode.delivery) {
        return this.deliveryInvoiceIsReceipt
          ? String(this.$t('paymentReceiptToDocument', { documentName: this.$t('deliveryInvoice') }))
          : String(this.$t('payInvoiceAndUploadReceipt', { documentName: this.$t('deliveryInvoice') }));
      }
      return this.deliveryInvoiceIsReceipt
        ? String(this.$t('thisInvoiceAndReceipt', { documentName: this.$t('deliveryInvoice'), role: this.$t('buyer') }))
        : String(this.$t('documentForPayment', { documentName: this.$t('deliveryInvoice'), role: this.$t('buyer') }));
    },
    subheaderClearanceText() {
      if (this.buyerMode.clearance) {
        return this.clearanceInvoiceIsReceipt
          ? String(this.$t('paymentReceiptToDocument', { documentName: this.$t('clearanceInvoice') }))
          : String(this.$t('payInvoiceAndUploadReceipt', { documentName: this.$t('clearanceInvoice') }));
      }
      return this.deliveryInvoiceIsReceipt
        ? String(this.$t('thisInvoiceAndReceipt', { documentName: this.$t('clearanceInvoice'), role: this.$t('buyer') }))
        : String(this.$t('documentForPayment', { documentName: this.$t('clearanceInvoice'), role: this.$t('buyer') }));
    },
  },
  watch: {
    dealCurrentStatus: {
      immediate: true,
      handler() {
        this.selectedDelivery = this.$store.state.deal.deal.delivery_type;
        this.selectedCustom = this.$store.state.deal.deal.clearance_type;
      },
    },
    isDeliveryPaymentSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateDeliveryVisible(false), 500);
        }
      },
    },
    isCustomsPaymentSent: {
      immediate: true,
      handler(value) {
        if (value) {
          setTimeout(() => this.updateClearanceVisible(false), 500);
        }
      },
    },
  },
  created() {
    const { clearance_price, delivery_price } = this.$store.state.deal.deal;
    this.delivery_cost = delivery_price;
    this.custom_cost = clearance_price;
  },
  methods: {
    updateDeliveryVisible(val = true) {
      this.isDeliveryVisible = val;
    },
    updateClearanceVisible(val = true) {
      this.isClearanceVisible = val;
    },
    async fetchDeal() {
      await this.$store.dispatch('deal/fetch', this.currentDeal.id);
    },
    async addDeliveryInvoice(files) {
      await this.savePaymentReceipt(files, 'delivery');

      if (this.deliveryInvoiceForPayment?.receipt_sent) {
        await this.sendDelivery();
      }
    },
    async addClearanceInvoice(files) {
      await this.savePaymentReceipt(files, 'clearance');

      if (this.clearanceInvoiceForPayment?.receipt_sent) {
        await this.sendClearance();
      }
    },
    async sendDelivery() {
      const { data } = await this.$store.dispatch('deal/getDocument', this.deliveryInvoice.id);
      const form = new FormData();

      form.set('receipt', data);
      form.set('stage', 'delivery');

      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async sendClearance() {
      const { data } = await this.$store.dispatch('deal/getDocument', this.clearanceInvoice.id);
      const form = new FormData();

      form.set('receipt', data);
      form.set('stage', 'clearance');

      await this.$store.dispatch('deal/setPaymentSent', { payload: form, id: this.currentRoute });
    },
    async savePaymentReceipt(files, invoiceType) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', `${invoiceType}_payment_receipt`);
      formData.append('link_id', String(this.buyerLegalId));
      formData.append('meta', `${invoiceType} payment receipt`);
      formData.append('file', file);

      if (this.buyerUserId) formData.append('owner_id', String(this.buyerUserId));

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        await this.fetchDeal();
      });
    },
    async selectDelivery(deliveryId) {
      this.selectedDelivery = deliveryId;
      await this.postVariant(this.selectedDelivery, 'delivery', 'delivery_type');
      if (this.selectedDelivery === 2) {
        await this.selectCustom(this.selectedDelivery);
      }
    },
    async selectCustom(customId) {
      this.selectedCustom = customId;
      await this.postVariant(customId, 'clearance', 'clearance_type');
    },
    async postVariant(id, type, paramName) {
      try {
        const params = new URLSearchParams();
        params.append(paramName, id);
        await this.$store.dispatch('deal/setDeliveryOptions', { payload: params, id: this.currentRoute, type });
      } catch (e) {
        console.log(e);
      }
    },
    async generateDeliveryInvoice() {
      try {
        const delivery = new URLSearchParams();
        delivery.append('delivery_price', this.delivery_cost);
        await this.$store.dispatch('deal/setPrice', { id: this.currentRoute, payload: delivery });
        this.isDeliveryInvoiceReady = true;
      } catch (e) {
        console.log(e);
      }
    },
    async generateClearanceInvoice() {
      try {
        const delivery = new URLSearchParams();
        delivery.append('clearance_price', this.custom_cost);
        await this.$store.dispatch('deal/setClearancePrice', { id: this.currentRoute, payload: delivery });
        this.isCustomInvoiceReady = true;
      } catch (e) {
        console.log(e);
      }
    },
    async sendDeliveryInvoiceToBuyer() {
      this.deliverySendingToBuyer = true;
      await this.sendInvoiceToBuyer();
      this.deliverySendingToBuyer = false;
    },
    async sendCustomsInvoiceToBuyer() {
      this.customsSendingToBuyer = true;
      await this.sendInvoiceToBuyer();
      this.customsSendingToBuyer = false;
    },
    async sendInvoiceToBuyer() {
      try {
        await this.$store.dispatch('deal/sendInvoiceToBuyer', this.currentRoute);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('invoiceSentSuccessfully'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('failedToSendInvoice'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    getAvatarText(data) {
      return avatarText(data);
    },
    inputNumberFocus(mode, checkValue) {
      switch (mode) {
        case 'in':
          if (!this[checkValue] || !Number(this[checkValue])) this[checkValue] = '';
          break;
        case 'out':
          if (this[checkValue] === '') this[checkValue] = 0;
          break;
        default:
      }
    },
    checkboxValueChanged(sectionType) {
      this.buyerMode[sectionType] = !this.buyerMode[sectionType];
      this.buyerMode = { ...this.buyerMode };
    },
  },
};
</script>

<style lang="scss" scoped>
.disable-card {
  opacity: 0.4;
  cursor: not-allowed;
  pointer-events: none;
}

.no-selectable {
  pointer-events: none;
}

.section-margin {
  margin-bottom: 21px;
}

.margin-top-12 {
  margin-top: 12px !important;
}
</style>
