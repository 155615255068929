<template>
  <b-card
    no-body
    class="d-flex flex-column align-items-center card-variant py-2 cursor-pointer h-100"
    :class="{ _active: variant.id === selectedVariant, _disabled: disabled }"
    @click="selectVariant"
  >
    <feather-icon
      :icon="variant.icon"
      size="48"
    />
    <div class="card-caption">
      <span>
        {{ variant.name }}
      </span>
    </div>
    <span
      class="card-variant-selector mt-1"
      :class="{ _active: variant.id === selectedVariant }"
    />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue';

export default {
  components: {
    BCard,
  },
  props: {
    variant: {
      type: Object,
      default: () => {},
    },
    selectedVariant: {
      type: Number,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    selectVariant() {
      if (!this.disabled) {
        this.$emit('select', this.variant.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.card-variant {
  border: 1px solid $table-border-color;
  border-radius: 6px;
  transition: all .4s ease;
  &-selector {
    height: 18px;
    width: 18px;
    border: 1px solid $body-color;
    border-radius: 50%;
    transition: all .2s ease;
    box-shadow: 3px 2px 2px rgba(110, 107, 123, .4);
  }
  &._active {
    border-color: $primary;
    .card-variant-selector {
      border: 5px solid $primary;
      box-shadow: 3px 2px 2px rgba(115, 103, 240, .4);
    }
    svg {
      color: $primary;
    }
  }
}
.dark-layout {
  .card-variant {
    border:  1px solid $theme-dark-widget-bg;
    &._active {
      border-color: $primary;
    }
  }
}

.card-caption {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 15px;
}
</style>
