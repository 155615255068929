<template>
  <div>
    <hr v-if="['before', 'beforeAndAfter'].includes(divider)" />

    <div class="header-div">
      <div
        class="header-text"
        :class="`header-text-${headerTextSize}`"
      >
        <span>{{ headerText }}</span>
      </div>
      <div v-if="showCheckbox">
        <b-form-checkbox
          :checked="checked"
          @change="changeValue"
        >
          <span class="checkbox-label">
            {{ this.$t('docHeaderCheckbox', { role, }) }}
          </span>
        </b-form-checkbox>
      </div>
    </div>

    <div class="sub-header-container">
      <span class="sub-header-text">
        {{ subHeaderText }}
      </span>
    </div>

    <slot name="content" />

    <hr
      v-if="['after', 'beforeAndAfter'].includes(divider)"
      class="divider-after"
    />
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue';

export default {
  components: {
    BFormCheckbox,
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    subHeaderText: {
      type: String,
      default: '',
    },
    headerTextSize: {
      type: String,
      default: 'large',
    },
    divider: {
      type: String,
      default: '',
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: '',
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    changeValue() {
      this.$emit('checkbox-value-changed');
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
</style>

<style scoped lang="scss">
.header-div {
  display: flex;
  justify-content: space-between;
}

.header-text {
  font-weight: 700;
  text-transform: capitalize;
}

.header-text-large {
  font-size: 18px;
  font-weight: 700;
}

.header-text-medium {
  font-size: 16px;
}

.header-text-small {
  font-size: 14px;
}

.sub-header-container {
  margin-top: 10px;
}

.sub-header-text {
  font-weight: 500;
}

.divider-after {
  margin-bottom: 21px;
  margin-top: 0;
}

.checkbox-label {
  font-weight: 500;
}
</style>
