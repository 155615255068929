<template>
  <b-card-body class="pt-0">
    <b-button
      variant="flat-primary"
      @click="showZone = !showZone"
    >
      <span class="d-none d-sm-inline">
        {{ $t('attachOtherDocuments') }}
      </span>
    </b-button>
    <drop-zone
      v-if="showZone"
      class="mt-2"
      :options="dropzoneOptions"
      @add-file="uploadFile"
    >
      <template #description>
        <div class="d-flex flex-column align-items-center">
          <h6 class="my-1">
            {{ $t('dropDocumentsToUpload') }}
          </h6>
          <span>
            {{ $t('allDocFormatsAllowed') }}
          </span>
        </div>
      </template>
    </drop-zone>
  </b-card-body>
</template>

<script>
import {
  BButton, BCardBody,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';

export default {
  components: {
    DropZone,
    BButton,
    BCardBody,
  },
  props: {
    legal: {
      type: Object,
      default: () => {},
    },
    documentType: {
      type: String,
      default: '',
    },
    dealId: {
      type: String,
      default: '',
    },
    ownerId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    showZone: false,
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      url: 'localhost',
    },
  }),
  computed: {
    legalId() {
      if (this.legal) return String(this.legal.id);
      return String(this.$store.state.profile.profile.company.id);
    },
  },
  methods: {
    async uploadFile(files) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', 'other');
      formData.append('meta', this.documentType);

      const role = this.$store.state.deal.deal.user_role;

      if (role === 'admin') {
        formData.append('link_id', String(this.legalId));

        if (this.ownerId) {
          formData.append('owner_id', this.ownerId);
        }
      }

      formData.append('file', file);

      this.showZone = false;

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        if (role === 'admin') {
          await this.$store.dispatch('deal/fetch', this.dealId);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
