<template>
  <div v-if="currentDocument">
    <div
      class="document-preview d-flex flex-column"
    >
      <div class="d-flex justify-content-between w-100 flex-wrap">
        <div
          class="d-flex align-items-center mw-80 my-2 doc-name"
          :class="`${classesList}`"
        >
          <div class="mr-1">
            <feather-icon
              icon="FileTextIcon"
              size="36"
            />
          </div>
          <div class="mw-100">
            <div class="d-flex flex-column">
              <div class="document-preview-type">
                {{ docName }}
              </div>
              <span class="text-muted">
                {{ getDate(currentDocument.invoice_creation_date || currentDocument.created_at) }}
              </span>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center justify-content-end">
          <div class="document-preview-buttons">
            <b-button
              class="mr-1"
              size="sm"
              @click="getPath(currentDocument)"
            >
              <feather-icon
                icon="DownloadIcon"
                size="18"
              />
            </b-button>
            <b-button
              target="_blank"
              size="sm"
              @click="getPath(currentDocument)"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
              />
            </b-button>
            <b-button
              v-if="isRemovable"
              size="sm"
              class="ml-1"
              @click="deleteConfirmVisible = true"
            >
              <feather-icon
                icon="XIcon"
                size="18"
              />
            </b-button>
          </div>
          <div
            v-if="isSigned"
            class="d-flex align-items-center document-preview-signed pointer-events-none"
          >
            <b-avatar
              class="mr-1"
              size="32"
              variant="light-success"
            >
              <feather-icon
                icon="CheckCircleIcon"
              />
            </b-avatar>
            <h6 class="mb-0">
              {{ $t('signed') }}
            </h6>
          </div>
        </div>
      </div>
      <drop-zone
        v-if="showZone"
        :options="dropzoneOptions"
        @add-file="uploadFile"
      >
        <template #description>
          <div class="d-flex flex-column align-items-center">
            <h6 class="my-1">
              {{ $t('dropHere') }}
              <u>{{ descriptionType }}</u>
              {{ $t('orClickToUpload') }}
            </h6>
            <span>
              {{ $t('PdfJpegPngAllowed') }}
            </span>
          </div>
        </template>
      </drop-zone>
    </div>

    <confirm-modal
      :modal-visible="deleteConfirmVisible"
      :modal-message="confirmMessage"
      :ok-text="okText"
      :cancel-text="cancelText"
      @modal-hidden="deleteConfirmVisible = false"
      @yes-clicked="removeDoc"
      @no-clicked="deleteConfirmVisible = false"
    />
  </div>
</template>

<script>
import { BButton, BAvatar } from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import confirmModal from '@/views/apps/components/modals/confirmModal.vue';
import i18n from '@/libs/i18n';

export default {
  components: {
    confirmModal,
    BButton,
    DropZone,
    BAvatar,
  },
  props: {
    document: {
      type: Object,
      default: () => {},
    },
    isSigned: {
      type: Boolean,
      default: false,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    isReceipt: {
      type: Boolean,
      default: false,
    },
    showZone: {
      type: Boolean,
      default: false,
    },
    legal: {
      type: Object,
      default: null,
    },
    role: {
      type: String,
      default: '',
    },
    classesList: {
      type: String,
      default: '',
    },
    confirmMessage: {
      type: String,
      default: String(i18n.t('deleteFileConfirmMessage')),
    },
    okText: {
      type: String,
      default: String(i18n.t('yesDelete')),
    },
    cancelText: {
      type: String,
      default: String(i18n.t('noCancel')),
    },
  },
  data: () => ({
    dropzoneOptions: {
      previewsContainer: false,
      autoProcessQueue: false,
      acceptedFiles: 'application/pdf,image/jpeg,image/png',
      url: 'localhost',
    },
    deleteConfirmVisible: false,
  }),
  computed: {
    docName() {
      if (this.currentDocument.type === 'supply_contract') return this.$t('supplyContract');
      if (this.currentDocument.type === 'packing') return this.$t('packingInfo');
      if (this.currentDocument.type === 'delivery') return this.$t('deliveryInvoice');
      if (this.currentDocument.type === 'purchase') return this.$t('purchaseInvoice');
      if (this.currentDocument.type === 'clearance') return this.$t('clearanceInvoice');

      return this.currentDocument.name || this.currentDocument.document_name;
    },
    descriptionType() {
      if (this.currentDocument.type === 'supply_contract') {
        return this.$t('salePurchaseAgreement');
      }
      if (this.currentDocument.type === 'purchase') {
        return this.$t('invoice');
      }
      if (this.currentDocument.type === 'packing') {
        return this.$t('packing');
      }
      return this.$t('dropCheckToUpload');
    },
    currentDocument() {
      return this.document;
    },
    legalId() {
      return String(this.$store.state.profile.profile.company.id);
    },
  },
  methods: {
    async uploadFile(files) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', 'supply_contract_signed');
      if (this.legal) formData.append('legal_id', String(this.legal.company_id));
      formData.append('meta', 'supply_contract_signed_2');
      formData.append('file', file);

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(() => {
        this.$emit('fetch-deal');
      });
    },
    async removeDoc() {
      const documentId = this.currentDocument.id;

      await this.$store.dispatch('deal/removeDocument', { documentId }).then(() => {
        this.$emit('fetch-deal');
      });

      this.deleteConfirmVisible = false;
    },
    async getPath(item) {
      let url = null;

      if (['purchase', 'delivery', ''].includes(item.type)) {
        url = item?.url;
      } else {
        const getUrl = await this.$store.dispatch('deal/getDocument', item.id);
        url = URL.createObjectURL(getUrl.data);
      }

      window.open(url, '_blank');
    },
    getDate(date) {
      if (date) {
        const newDate = new Date(date);
        const day = newDate.getDate() < 9 ? `0${newDate.getDate()}` : newDate.getDate();
        const month = (newDate.getMonth() + 1) <= 9 ? `0${(newDate.getMonth() + 1)}` : newDate.getMonth() + 1;
        return `${day}.${month}.${newDate.getFullYear()}`;
      }

      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
.doc-name {
  width: 80% !important;
}

.margin-top-12 {
  margin-top: 12px !important;
}

.margin-bottom-12 {
  margin-bottom: 12px !important;
}

.margin-top-bottom-12 {
  margin: 12px 0 !important;
}
</style>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
.document-preview {
  cursor: pointer;
  overflow: hidden;
  &-buttons {
    opacity: 0;
    transition: all .4s ease;
  }
  &-signed {
    position: absolute;
    transition: all .4s ease;
    opacity: 1;
  }
  &:hover {
    .document-preview-buttons {
      opacity: 1;
    }
    .document-preview-signed {
      opacity: 0;
    }
  }
  &-type {
    font-weight: bold;
    text-transform: none;
  }
  &-type::first-letter {
    text-transform: uppercase;
  }
}
</style>
