<template>
  <div
    :id="`infinite-list__${role}`"
    class="chats"
  >
    <div class="chat">
      <div
        v-for="(msgGrp, index) in chatMessages"
        :key="`message_${index}`"
        :class="{'chat-left': msgGrp.user.id !== userId}"
      >
        <div :class="{'text-right': msgGrp.user.id === userId}">
          <b-avatar
            size="36"
            class="avatar-border-2 box-shadow-1"
            variant="transparent"
            :src="msgGrp.user.id !== userId ? msgGrp.user.profile.avatar_url : currentUser.profile.avatar_url"
          />
        </div>
        <div
          :class="[{'text-left': msgGrp.user.id !== userId}, {'text-right': msgGrp.user.id === userId}]"
          class="chat-body"
        >
          <template v-if="msgGrp.user.id !== userId">
            <b-button
              v-if="!msgGrp.read_at"
              variant="text"
              class="p-25"
              :disabled="msgGrp.read_at"
              @click="readMsg(msgGrp.id)"
            >
              <feather-icon
                icon="EyeIcon"
                size="15"
              />
            </b-button>
            <feather-icon
              v-else
              icon="CheckIcon"
              size="23"
              color="#28c76f"
              class="p-25"
            />
          </template>
          <template v-else-if="msgGrp.user.id === userId && msgGrp.read_at">
            <feather-icon
              icon="CheckIcon"
              size="23"
              color="#28c76f"
              class="p-25"
            />
          </template>
          <div
            class="chat-content"
          >
            <p>{{ msgGrp.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import { BAvatar, BButton } from 'bootstrap-vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    BAvatar,
    BButton,
  },
  props: {
    chatMessages: {
      type: Array,
    },
    role: {
      type: String,
    },
  },
  data() {
    return {
      chatLog: [],
    };
  },
  methods: {
    ...mapActions({
      fetchMessages: 'deal/fetchMessages',
    }),
    readMsg(id) {
      this.$emit('read-message', [id]);
    },
    infiniteScroll() {
      const listElm = document.getElementById(`infinite-list__${this.role}`);
      listElm.addEventListener('scroll', e => {
        if (listElm.scrollTop <= 200) {
          this.$emit('load-next-page');
        }
      });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.infiniteScroll();
    });
  },
  computed: {
    ...mapGetters({
      user: 'profile/profile',
      chatBuyer: 'deal/chatBuyer',
      chatSupplier: 'deal/chatSupplier',
    }),
    currentUser() {
      return this.user;
    },
    userId() {
      return this.currentUser.id || null;
    },
  },
};
</script>

<style lang="scss">
@import '../../../@core/scss/base/core/colors/palette-variables';

$chat-image-back-color: #f2f0f7;
$chat-head-footer-height: 65px;
$chat-widget-head-footer-height: 56px;
$chat-widget-height: 390px;

.chats {
  padding: 0 15px;
  .chat-avatar {
    float: right;
  }
  .chat-body {
    display: block;
    margin: 10px 30px 0 0;
    overflow: hidden;
    .chat-content {
      float: right;
      padding: 0.7rem 1rem;
      margin: 0 1rem 10px 0;
      clear: both;
      color: $white;
      @include gradient-directional(map-get($primary-color, 'base'), map-get($primary-color, 'lighten-2'), 80deg);
      border-radius: $border-radius;
      box-shadow: 0 4px 8px 0 rgba($black, 0.12);
      max-width: 75%;
      p {
        margin: 0;
      }
    }
  }
  // Chat area left css
  .chat-left {
    .chat-avatar {
      float: left;
    }
    .chat-body {
      .chat-content {
        float: left;
        margin: 0 0 10px 1rem;
        color: $body-color;
        background: none;
        background-color: lighten($white, 18%);
      }
    }
  }
}
</style>
