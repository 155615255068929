<template>
  <b-card no-body>
    <b-card-header v-if="title">
      <h5>
        {{ title }}
      </h5>
    </b-card-header>
    <b-card-body>
      <ActionRequired :actions="actions" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader,
} from 'bootstrap-vue';
import ActionRequired from '@/views/apps/components/ActionRequired.vue';

export default {
  components: {
    ActionRequired,
    BCard,
    BCardBody,
    BCardHeader,
  },
  props: {
    title: {},
    actions: {
      type: Array,
      required: true,
    },
  },
};
</script>
