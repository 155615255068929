<template>
  <div>
    <div
      v-for="action in preparedActions"
      :key="action.action"
      class="pb-1"
    >
      <b-avatar
        size="32"
        :variant="`light-${action.variant}`"
      >
        <feather-icon
          :icon="action.icon"
        />
      </b-avatar>
      <span class="ml-50 action-text text-wrap">
        {{ action.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue';
import { prepareAction } from '@/constants/actionRequired.js';

export default {
  name: 'ActionRequired',
  components: {
    BAvatar,
  },
  props: {
    actions: {
      type: Array,
      required: true,
    },
  },
  computed: {
    preparedActions() {
      if (this.actions.length === 0) {
        return [
          {
            icon: 'CheckCircleIcon',
            variant: 'success',
            action: 'no_actions',
            text: 'No actions required',
          },
        ];
      }

      return this.actions.map(action => prepareAction(action));
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
.action-text {
  font-weight: 500;
  font-size: .85rem;
}
</style>
