<template>
  <b-card-body>
    <template v-if="documents.length">
      <div v-if="supplyContract">
        <doc-section-header
          :header-text="String($t('supplyContract'))"
          :sub-header-text="String(supplyContract.type === 'supply_contract' ?
            $t('signDocumentAndUpload', {documentName: $t('supplyContract')})
            : $t('thisSignedDocument', {documentName: $t('supplyContract')}))"
          divider="after"
        >
          <template #content>
            <template v-if="supplyContract.type === 'supply_contract_signed'">
              <transactions-document-sign
                :document="supplyContract"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :is-signed="true"
                :is-removable="true"
                @fetch-deal="fetchDeal"
              />
            </template>
            <template v-else>
              <transactions-document-sign
                :document="supplyContract"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                @fetch-deal="fetchDeal"
              />
              <drop-zone
                class="section-margin"
                :confirm-message="String($t('deleteDocumentConfirmMessage'))"
                :save-button-text="String($t('saveDocument'))"
                @add-file="addSupplyContractSigned"
              />
            </template>
          </template>
        </doc-section-header>
      </div>

      <div v-if="documents.find(doc => doc.type === 'packing')">
        <doc-section-header
          :header-text="String($t('packingInfo'))"
          :sub-header-text="String($t('infoPackingProducts'))"
          divider="after"
        >
          <template #content>
            <transactions-document-sign
              :document="documents.find(doc => doc.type === 'packing')"
              :confirm-message="String($t('deleteDocumentConfirmMessage'))"
              @fetch-deal="fetchDeal"
            />
          </template>
        </doc-section-header>
      </div>
    </template>
    <template v-else>
      <h5 class="section-margin">
        {{ $t('documentsWillHereSoon') }}
      </h5>
    </template>

    <template v-if="purchaseInvoice">
      <doc-section-header
        :header-text="String($t('payments'))"
        :sub-header-text="!purchaseInvoiceIsReceipt ?
          String($t('payInvoiceAndUploadReceipt', { documentName: this.$t('purchaseInvoice') }))
          : String($t('paymentReceiptToDocument', { documentName: this.$t('purchaseInvoice') }))"
        divider="after"
      >
        <template #content>
          <transactions-documents-list
            :documents="[purchaseInvoice]"
            :is-receipt="purchaseInvoiceIsReceipt"
            :is-removable="purchaseInvoiceIsReceipt && currentDeal.status === 2"
            @fetch-deal="fetchDeal"
          />
          <drop-zone
            v-if="!purchaseInvoiceIsReceipt"
            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
            @add-file="addInvoice"
          />
          <template v-if="!purchaseInvoiceForPayment.receipt_sent">
            <b-button
              class="mt-1 section-margin"
              variant="primary"
              :disabled="!purchaseInvoiceIsReceipt"
              @click="sendPayment"
            >
              {{ $t('paymentSent') }}
            </b-button>
          </template>
          <template v-else>
            <div
              class="d-flex align-items-center section-margin"
              :class="{'margin-top-12' : !purchaseInvoiceIsReceipt}"
            >
              <b-avatar
                size="32"
                variant="light-success"
              >
                <feather-icon
                  icon="CheckCircleIcon"
                />
              </b-avatar>
              <h5 class="mb-0 ml-1">
                {{ $t('paymentSent') }}
              </h5>
            </div>
          </template>
        </template>
      </doc-section-header>
    </template>
    <div>
      <div>
        <div>
          <div class="document-name">
            <span>{{ $t('otherDocuments') }}</span>
          </div>
          <transactions-document-sign
            v-for="(item, index) in documents.filter(doc => ![
              'packing',
              'packing_signed',
              'supply_contract',
              'supply_contract_signed',
              'purchase',
              'purchase_payment_receipt',
              'clearance',
              'clearance_payment_receipt',
              'delivery',
              'delivery_payment_receipt'
            ].includes(doc.type))"
            :key="index"
            :document="item"
            :confirm-message="String($t('deleteDocumentConfirmMessage'))"
            :is-removable="true"
            :classes-list="index ? 'margin-top-bottom-12' : 'margin-bottom-12'"
            @fetch-deal="fetchDeal"
          />
        </div>

        <deal-other-docs
          document-type="transaction"
          class="mt-0 p-0"
        />
      </div>
    </div>
  </b-card-body>
</template>

<script>
import {
  BCardBody, BButton, BAvatar,
} from 'bootstrap-vue';
import DropZone from '@/views/apps/components/DropZone.vue';
import TransactionsDocumentsList from '@/views/trading/trading-deals/transactions/TransactionsDocumentsList.vue';
import TransactionsDocumentSign from '@/views/trading/trading-deals/transactions/TransactionsDocumentSign.vue';
import DealOtherDocs from '@/views/trading/trading-deals/DealOtherDocs.vue';
import DocSectionHeader from '@/views/trading/trading-deals/components/DocSectionHeader.vue';

export default {
  components: {
    DealOtherDocs,
    BAvatar,
    BCardBody,
    BButton,
    DropZone,
    TransactionsDocumentSign,
    TransactionsDocumentsList,
    DocSectionHeader,
  },
  props: {
    transactions: {
      type: Array,
      default: () => [],
    },
    deal: {
      type: Object,
      default: () => {
      },
    },
  },
  data: () => ({
    hoveredDocument: null,
    margin: null,
    invoicePaymentReceipt: null,
  }),
  computed: {
    documents() {
      return this.purchaseInvoice ? [...this.currentDeal.legal.documents, this.purchaseInvoice] : [...this.currentDeal.legal.documents];
    },
    purchaseInvoice() {
      const purchaseInvoiceReceipt = this.currentDeal.legal.documents.find(
        item => item.type === 'purchase_payment_receipt',
      );

      if (purchaseInvoiceReceipt) {
        return purchaseInvoiceReceipt;
      }

      return this.currentDeal.legal.invoices.find(item => item.type === 'purchase');
    },
    purchaseInvoiceForPayment() {
      return this.currentDeal.legal.invoices.find(item => item.type === 'purchase');
    },
    purchaseInvoiceIsReceipt() {
      return this.purchaseInvoice.type === 'purchase_payment_receipt';
    },
    supplyContract() {
      const supplyContractSigned = this.currentDeal.legal.documents.find(
        item => item.type === 'supply_contract_signed',
      );

      if (supplyContractSigned) {
        return supplyContractSigned;
      }

      return this.currentDeal.legal.documents.find(item => item.type === 'supply_contract');
    },
    isAllDocumentsSigned() {
      return Object.values(this.currentDeal.uploaded_documents).some(value => !!value);
    },
    currentDeal() {
      return this.deal;
    },
  },
  methods: {
    addInvoice(files) {
      const [file] = files;
      this.invoicePaymentReceipt = file;

      const needSendPayment = !!this.purchaseInvoiceForPayment?.receipt_sent;

      this.savePaymentReceipt(files, 'purchase');

      if (needSendPayment) {
        this.sendPayment();
      }
    },
    fetchDeal() {
      this.$emit('fetch-deal');
    },
    async addSupplyContractSigned(files) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', 'supply_contract_signed');
      formData.append('legal_id', String(this.$store.state.profile.profile.company.id));
      formData.append('meta', 'Signed supply contract');
      formData.append('file', file);

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        this.fetchDeal();
      });
    },
    async savePaymentReceipt(files, invoiceType) {
      const [file] = files;
      const formData = new FormData();

      formData.append('type', `${invoiceType}_payment_receipt`);
      formData.append('legal_id', String(this.$store.state.profile.profile.company.id));
      formData.append('meta', `${invoiceType} payment receipt`);
      formData.append('file', file);

      await this.$store.dispatch('deal/uploadDocument', { formData }).then(async () => {
        this.fetchDeal();
      });
    },
    async sendPayment() {
      const { data } = await this.$store.dispatch('deal/getDocument', this.purchaseInvoice.id);
      const formData = new FormData();

      formData.append('receipt', data);
      formData.append('stage', 'purchase');

      await this.$store.dispatch('deal/setPaymentSent', { payload: formData, id: this.$route.params.id });
    },
  },
};
</script>

<style lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";
</style>

<style scoped lang="scss">
.document-name {
  font-size: 16px;
  font-weight: 700;
  text-transform: capitalize;
}

.section-margin {
  margin-bottom: 21px;
}

.margin-top-12 {
  margin-top: 12px !important;
}
</style>
