var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card-body',[(_vm.documents.length)?[(_vm.supplyContract)?_c('div',[_c('doc-section-header',{attrs:{"header-text":String(_vm.$t('supplyContract')),"sub-header-text":String(_vm.supplyContract.type === 'supply_contract' ?
          _vm.$t('signDocumentAndUpload', {documentName: _vm.$t('supplyContract')})
          : _vm.$t('thisSignedDocument', {documentName: _vm.$t('supplyContract')})),"divider":"after"},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.supplyContract.type === 'supply_contract_signed')?[_c('transactions-document-sign',{attrs:{"document":_vm.supplyContract,"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage')),"is-signed":true,"is-removable":true},on:{"fetch-deal":_vm.fetchDeal}})]:[_c('transactions-document-sign',{attrs:{"document":_vm.supplyContract,"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage'))},on:{"fetch-deal":_vm.fetchDeal}}),_c('drop-zone',{staticClass:"section-margin",attrs:{"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage')),"save-button-text":String(_vm.$t('saveDocument'))},on:{"add-file":_vm.addSupplyContractSigned}})]]},proxy:true}],null,false,3447677789)})],1):_vm._e(),(_vm.documents.find(function (doc) { return doc.type === 'packing'; }))?_c('div',[_c('doc-section-header',{attrs:{"header-text":String(_vm.$t('packingInfo')),"sub-header-text":String(_vm.$t('infoPackingProducts')),"divider":"after"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('transactions-document-sign',{attrs:{"document":_vm.documents.find(function (doc) { return doc.type === 'packing'; }),"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage'))},on:{"fetch-deal":_vm.fetchDeal}})]},proxy:true}],null,false,2556868320)})],1):_vm._e()]:[_c('h5',{staticClass:"section-margin"},[_vm._v(" "+_vm._s(_vm.$t('documentsWillHereSoon'))+" ")])],(_vm.purchaseInvoice)?[_c('doc-section-header',{attrs:{"header-text":String(_vm.$t('payments')),"sub-header-text":!_vm.purchaseInvoiceIsReceipt ?
        String(_vm.$t('payInvoiceAndUploadReceipt', { documentName: this.$t('purchaseInvoice') }))
        : String(_vm.$t('paymentReceiptToDocument', { documentName: this.$t('purchaseInvoice') })),"divider":"after"},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('transactions-documents-list',{attrs:{"documents":[_vm.purchaseInvoice],"is-receipt":_vm.purchaseInvoiceIsReceipt,"is-removable":_vm.purchaseInvoiceIsReceipt && _vm.currentDeal.status === 2},on:{"fetch-deal":_vm.fetchDeal}}),(!_vm.purchaseInvoiceIsReceipt)?_c('drop-zone',{attrs:{"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage'))},on:{"add-file":_vm.addInvoice}}):_vm._e(),(!_vm.purchaseInvoiceForPayment.receipt_sent)?[_c('b-button',{staticClass:"mt-1 section-margin",attrs:{"variant":"primary","disabled":!_vm.purchaseInvoiceIsReceipt},on:{"click":_vm.sendPayment}},[_vm._v(" "+_vm._s(_vm.$t('paymentSent'))+" ")])]:[_c('div',{staticClass:"d-flex align-items-center section-margin",class:{'margin-top-12' : !_vm.purchaseInvoiceIsReceipt}},[_c('b-avatar',{attrs:{"size":"32","variant":"light-success"}},[_c('feather-icon',{attrs:{"icon":"CheckCircleIcon"}})],1),_c('h5',{staticClass:"mb-0 ml-1"},[_vm._v(" "+_vm._s(_vm.$t('paymentSent'))+" ")])],1)]]},proxy:true}],null,false,3626461588)})]:_vm._e(),_c('div',[_c('div',[_c('div',[_c('div',{staticClass:"document-name"},[_c('span',[_vm._v(_vm._s(_vm.$t('otherDocuments')))])]),_vm._l((_vm.documents.filter(function (doc) { return ![
            'packing',
            'packing_signed',
            'supply_contract',
            'supply_contract_signed',
            'purchase',
            'purchase_payment_receipt',
            'clearance',
            'clearance_payment_receipt',
            'delivery',
            'delivery_payment_receipt'
          ].includes(doc.type); })),function(item,index){return _c('transactions-document-sign',{key:index,attrs:{"document":item,"confirm-message":String(_vm.$t('deleteDocumentConfirmMessage')),"is-removable":true,"classes-list":index ? 'margin-top-bottom-12' : 'margin-bottom-12'},on:{"fetch-deal":_vm.fetchDeal}})})],2),_c('deal-other-docs',{staticClass:"mt-0 p-0",attrs:{"document-type":"transaction"}})],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }